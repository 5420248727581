import CodeMirror from "codemirror";
import "codemirror/lib/codemirror.css";
import "codemirror/mode/javascript/javascript";
import "codemirror/addon/mode/simple";
import "codemirror/addon/display/placeholder";
import "./themes/decode.css";
import "./themes/default-ro.css";
import { ContextRegex, CallerRegex } from "../util/variables";

(CodeMirror as any).defineSimpleMode("simple-decode-vars", {
  start: [
    { regex: new RegExp(ContextRegex), token: "variable-2" },
    { regex: new RegExp(CallerRegex), token: "variable" },
  ],
  comment: [],
  meta: {},
});

import React from "react";
import * as Variables from "../util/variables";

export default function QueryValue({ value }: { value: string }) {
  let split = Variables.splitString(value);
  let withSpans = split.map((s, idx) => {
    if (typeof s === "string") {
      return s;
    }
    let { name, provider } = s;
    return (
      <span key={idx} className={`provider provider-${provider}`}>
        {name}
      </span>
    );
  });
  return (
    <pre className="query-value">
      {withSpans}

      <style jsx>{`
        .query-value {
          background: #fff;
          color: rgba(0, 0, 0, 0.65);
          padding: 4px;
          height: auto;
          margin: 5px 0px;
          overflow-x: auto;
          white-space: pre-wrap;
          white-space: -moz-pre-wrap;
          white-space: -pre-wrap;
          white-space: -o-pre-wrap;
          word-wrap: break-word;
        }
        .query-value :global(span.provider) {
          padding: 2px;
          border-radius: 6px;
        }
        .query-value :global(span.provider-caller) {
          color: #030852;
          background-color: #d6e4ff;
        }
        .query-value :global(span.provider-context) {
          color: #092b00;
          background-color: #f6ffed;
        }
      `}</style>
    </pre>
  );
}

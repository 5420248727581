import {
  HttpResource,
  HttpResourceModifiableParams,
  DbResourceModifiableParams,
  ApiError,
  apiErrorSchema,
  User,
  userSchema,
} from "../api/types";
import { HttpQueryModifiableParams } from "../components/EditHttpQuery";
import { DbQueryModifiableParams } from "../components/EditDbQuery";
import { err, ok, Result } from "neverthrow";

export enum AuthProvider {
  google = "google",
  github = "github",
  identity = "identity",
}

export type ValidationErrors = {
  name: string | string[];
  errors: string[];
}[];

const API_HOST = process.env.REACT_APP_API_HOST;
if (!API_HOST) {
  throw new Error(`Expected the ENV variable "REACT_APP_API_HOST" to be set.`);
}

let getUrl = (s: string) => {
  return [API_HOST, s.replace(/^\//, "")].join("/");
};

export let getLogoutUrl = () => getUrl("/auth/logout");

export let getLoginRedirectUrl = (
  provider: AuthProvider,
  redirectUrl?: string
) => {
  if (redirectUrl) {
    return getUrl(`/auth/${provider}?redirect_url=${redirectUrl}`);
  } else {
    return getUrl(
      `/auth/${provider}?redirect_url=${process.env.REACT_APP_HOST}/queries`
    );
  }
};

export let getLoginRedirectUrlOrgOauth = (
  orgSlug: string,
  redirectUrl?: string
) => {
  if (redirectUrl) {
    return getUrl(`/auth/org_oauth/${orgSlug}?redirect_url=${redirectUrl}`);
  } else {
    return getUrl(
      `/auth/org_oauth/${orgSlug}?redirect_url=${process.env.REACT_APP_HOST}/queries`
    );
  }
};

export let checkIsLoggedIn = async () => {
  let res = await fetch(getUrl(`/auth/check`), {
    credentials: "include",
  });
  return res.ok;
};

export let exchangeLoginCodeForToken = async (
  code: string,
  provider: AuthProvider
) => {
  let res = await fetch(getUrl(`auth/callback/${provider}`), {
    credentials: "include",
    method: "post",
    body: JSON.stringify({ code }),
    headers: { "Content-Type": "application/json" },
  });
  let j = await res.json();
  if (j.ok) {
    return j.token;
  } else {
    let err =
      j.error?.summary ||
      `Received an error when trying to exchange code (provider: ${provider})`;
    console.error(j);
    throw new Error(err);
  }
};

interface CreateHttpQueryParams extends HttpQueryModifiableParams {
  resourceId: string;
}
export const createHttpQuery = async (params: CreateHttpQueryParams) => {
  let { slug, resourceId, roles, ...rest } = params;
  let p = {
    slug,
    resourceId,
    roles,
    definition: rest,
  };
  let r = await fetch(getUrl("/api/http_queries"), {
    credentials: "include",
    method: "post",
    body: JSON.stringify({ query: p }),
    headers: {
      "content-type": "application/json",
    },
  });
  if (r.status !== 201) {
    throw new Error(
      `Received status ${r.status} when trying to create the query`
    );
  }
  let j: { id: string } = await r.json();
  return j.id;
};

export const updateHttpQuery = async (
  id: string,
  params: HttpQueryModifiableParams
) => {
  let { slug, roles, ...rest } = params;
  let p = {
    slug,
    roles,
    definition: rest,
  };
  let r = await fetch(getUrl("/api/http_queries/" + id), {
    credentials: "include",
    method: "put",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ query: p }),
  });
  if (r.status !== 200) {
    throw new Error(
      `Received status ${r.status} when trying to update the query`
    );
  }
};

export const deleteDbResource = async (id: string) => {
  let r = await fetch(getUrl(`/api/db_resources/${id}`), {
    credentials: "include",
    method: "delete",
  });
  if (r.status !== 200) {
    throw new Error(
      `Received status ${r.status} when trying to delete the query`
    );
  }
};
export const deleteHttpResource = async (id: string) => {
  let r = await fetch(getUrl(`/api/http_resources/${id}`), {
    credentials: "include",
    method: "delete",
  });
  if (r.status !== 200) {
    throw new Error(
      `Received status ${r.status} when trying to delete the query`
    );
  }
};

export const deleteDbQuery = async (id: string) => {
  let r = await fetch(getUrl(`/api/db_queries/${id}`), {
    credentials: "include",
    method: "delete",
  });
  if (r.status !== 200) {
    throw new Error(
      `Received status ${r.status} when trying to delete the query`
    );
  }
};
export const deleteHttpQuery = async (id: string) => {
  let r = await fetch(getUrl(`/api/http_queries/${id}`), {
    credentials: "include",
    method: "delete",
  });
  if (r.status !== 200) {
    throw new Error(
      `Received status ${r.status} when trying to delete the query`
    );
  }
};

interface CreateDbQueryParams extends DbQueryModifiableParams {
  resourceId: string;
}
export const createDbQuery = async (params: CreateDbQueryParams) => {
  let r = await fetch(getUrl("/api/db_queries"), {
    credentials: "include",
    method: "post",
    body: JSON.stringify({ query: params }),
    headers: {
      "content-type": "application/json",
    },
  });
  if (r.status !== 201) {
    throw new Error(
      `Received status ${r.status} when trying to create the query`
    );
  }
  let j: { id: string } = await r.json();
  return j.id;
};

export const updateDbQuery = async (
  id: string,
  params: DbQueryModifiableParams
) => {
  let r = await fetch(getUrl("/api/db_queries/" + id), {
    credentials: "include",
    method: "put",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ query: params }),
  });
  if (r.status !== 200) {
    throw new Error(
      `Received status ${r.status} when trying to update the query`
    );
  }
};

export const createDbResource = async (
  params: DbResourceModifiableParams
): Promise<Result<true, ApiError>> => {
  let { dbKind: kind, ...rest } = params;
  let r = await fetch(getUrl("/api/db_resources/"), {
    credentials: "include",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      resource: {
        ...rest,
        kind,
      },
    }),
  });

  let result = await r.json();

  if (result.ok) {
    return ok(true);
  } else {
    let error = apiErrorSchema.parse(result);
    return err(error);
  }
};

export const updateDbResource = async (
  id: string,
  params: DbResourceModifiableParams
) => {
  let { dbKind: kind, ...rest } = params;
  let r = await fetch(getUrl("/api/db_resources/" + id), {
    credentials: "include",
    method: "put",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      resource: {
        ...rest,
        kind,
      },
    }),
  });
  if (r.status !== 200) {
    throw new Error(
      `Received status ${r.status} when trying to update the db resource`
    );
  }
};

export const createHttpResource = async (
  params: HttpResourceModifiableParams
) => {
  let {
    definition: { securityScheme },
  } = params;
  let securitySchemes: HttpResource["definition"]["securityScheme"][];
  if (securityScheme.scheme === "none") {
    securitySchemes = [];
  } else {
    securitySchemes = [securityScheme];
  }
  let r = await fetch(getUrl("/api/http_resources/"), {
    credentials: "include",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      resource: {
        ...params,
        definition: {
          ...params.definition,
          securitySchemes,
        },
      },
    }),
  });

  if (r.status !== 201) {
    throw new Error(
      `Received status ${r.status} when trying to create the http resource`
    );
  }
};

export const updateHttpResource = async (
  id: string,
  params: HttpResourceModifiableParams
) => {
  let {
    definition: { securityScheme },
  } = params;
  let securitySchemes: HttpResource["definition"]["securityScheme"][];
  if (securityScheme.scheme === "none") {
    securitySchemes = [];
  } else {
    securitySchemes = [securityScheme];
  }
  let r = await fetch(getUrl("/api/http_resources/" + id), {
    credentials: "include",
    method: "put",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      resource: {
        ...params,
        definition: {
          ...params.definition,
          securitySchemes,
        },
      },
    }),
  });
  if (r.status !== 200) {
    throw new Error(
      `Received status ${r.status} when trying to update the db resource`
    );
  }
};

export const loginWithPassword = async (
  email: string,
  password: string,
  redirectUrl?: string
): Promise<[string | null, string | null]> => {
  let redirect = redirectUrl
    ? redirectUrl
    : `${process.env.REACT_APP_HOST}/queries`;
  let res = await fetch(getUrl("/auth/identity/callback"), {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({ email, password, redirect_url: redirect }),
  });
  if (res.status !== 200) {
    console.error(res);
    return ["invalid username or password provided", null];
  }
  let { url, user } = await res.json();
  try {
    window.analytics.identify(user.id, {
      org_id: user.orgId,
      email: user.email,
      createdAt: user.insertedAt,
    });
    window.analytics.group(user.orgId);
  } catch (e) {}
  return [null, url];
};

export interface RegisterUser {
  user: {
    email: string;
    password: string;
  };
}

export const registerUser = async (
  payload: RegisterUser
): Promise<Result<User, ApiError>> => {
  let response = await fetch(getUrl("/auth/signup"), {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(payload),
  });

  let result = await response.json();

  if (result.ok) {
    let user = userSchema.parse(result.user);
    try {
      window.analytics.identify(user.id, {
        org_id: user.orgId,
        email: user.email,
        createdAt: user.insertedAt,
      });
      window.analytics.group(user.orgId);
    } finally {
      return ok(user);
    }
  } else {
    let error = apiErrorSchema.parse(result);
    return err(error);
  }
};

type TestQueryResponse =
  | { ok: true; result: unknown }
  | { ok: false; error: { summary: string; upstreamError?: string } };

export type TestField = { name: string; value: string };

export const testDbQuery = async (
  queryValue: string,
  databaseId: string,
  fields: TestField[]
): Promise<TestQueryResponse> => {
  const r = await fetch(getUrl("/api/test/db_query"), {
    credentials: "include",
    method: "post",
    body: JSON.stringify({
      query: {
        statement: queryValue,
        resourceId: databaseId,
        params: fields,
      },
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });

  return await r.json();
};

export const testDbResource = async (
  dbResource: DbResourceModifiableParams,
  id?: string
): Promise<TestQueryResponse> => {
  let { dbKind: kind, ...rest } = dbResource;

  const r = await fetch(getUrl("/api/test/db_resource"), {
    credentials: "include",
    method: "post",
    body: JSON.stringify({
      resource: {
        ...rest,
        id,
        kind,
      },
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });

  try {
    return await r.json();
  } catch (err) {
    // this happens only if the response JSON fails to parse
    // that's usually done in Dev environment because sometimes we can get
    // Phoenix's Error page and HTML cannot be parsed as JSON
    // that's because ChoamWeb.Endpoint has set `debug_errors: true`
    return {
      ok: false,
      error: {
        summary: "Cannot connect to DB with specified parameters",
      },
    };
  }
};

export interface TestResult {
  ok: boolean;
  log: string;
  query: string;
}

export type HttpParam = { [key: string]: string };

export interface TestHttpQueryInput {
  httpResourceId: string;
  definition?: any;
  parameters?: any;
}

export const testHttpQuery = async ({
  httpResourceId,
  definition,
  parameters,
}: TestHttpQueryInput): Promise<TestQueryResponse> => {
  const r = await fetch(getUrl("/api/test/http_query"), {
    credentials: "include",
    method: "post",
    body: JSON.stringify({
      resourceId: httpResourceId,
      definition,
      parameters,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });

  return await r.json();
};

export class NotAuthorizedError extends Error {}
export class ForbiddenError extends Error {}
export class FetchError extends Error {
  status: number;
  constructor(message: string, status: number) {
    super(message);

    this.status = status;
  }
}
import React, { useState } from "react";
import { Tooltip, Button, Input, message, Popconfirm } from "antd";
import { ResourceKind, HttpQuery, DbQuery, IResourceKind } from "../api/types";
import {
  DatabaseOutlined,
  CloudUploadOutlined,
  RightOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import MainLayout, { WhiteBox } from "../components/MainLayout";
import { useHistory } from "react-router-dom";
import useFullObjectSearch from "../util/useFullObjectSearch";
import { deleteDbQuery, deleteHttpQuery } from "../api";
import { Moment } from "moment";
import { useQueries } from "../api/hooks";

let { Search } = Input;

export default function AllQueries() {
  let [filterFocus, setFilterFocus] = useState(false);
  let [filter, setFilter] = useState("");
  let { queries: apiQueries, mutate } = useQueries();
  let queries = apiQueries && normalize(apiQueries);
  let filterResults = useFullObjectSearch(filter, queries ?? []);
  let [queriesDeleting, setQueriesDeleting] = useState<string[]>([]);
  let history = useHistory();

  let rows: Query[];
  if (filter.length > 0) {
    rows = filterResults.indexes.map((idx) => (queries as Query[])[idx]);
  } else {
    rows = queries;
  }

  // highlight strings matching filter
  let h = (propName: "queryPreview" | "slug" | "resourceName", idx: number) => {
    let query = rows[idx];
    let value = query[propName];
    let f = filterResults.matches[idx];

    if (!f) {
      return value;
    }

    if (f.propName === propName) {
      let [s, e] = f.matchRange;
      return [
        value.slice(0, s),
        <span className="inline-highlight">{value.slice(s, e)}</span>,
        value.slice(e),
      ];
    } else {
      return value;
    }
  };

  let handleDelete = async (id: string, kind: IResourceKind) => {
    // ts happy
    if (!apiQueries) {
      throw new Error(
        `Somehow no queries were loaded but handleDelete was called`
      );
    }

    try {
      if (kind === ResourceKind.Values.db) {
        setQueriesDeleting((queries) => queries.concat(id));
        await deleteDbQuery(id);
        let { db } = apiQueries;
        let idx = db.findIndex((q) => q.id === id);
        mutate({
          ...apiQueries,
          db: [...db.slice(0, idx), ...db.slice(idx + 1)],
        });
        message.success("Deleted");
      } else {
        setQueriesDeleting((queries) => queries.concat(id));
        await deleteHttpQuery(id);
        let { http } = apiQueries;
        let idx = http.findIndex((q) => q.id === id);
        mutate({
          ...apiQueries,
          http: [...http.slice(0, idx), ...http.slice(idx + 1)],
        });
        message.success("Deleted");
      }
    } catch (e) {
      console.error(e);
      message.error("There was an error :( Try one more time?");
    }
    setQueriesDeleting((queries) => {
      return queries.filter((i) => i !== id);
    });
  };

  let hasQueries = queries.length > 0;

  return (
    <MainLayout>
      <div className="header">
        <h1>Queries</h1>
        <Search
          autoFocus
          value={filter}
          onChange={(e) => setFilter(e.currentTarget.value)}
          placeholder="Filter..."
          style={{ width: 200 }}
          onKeyDown={(k) => {
            if (k.key === "Enter") {
              let q = rows[0];
              history.push(
                `/queries/${q.permaslug}/resources/${q.resourcePermaslug}`
              );
            }
          }}
          onFocus={() => setFilterFocus(true)}
          onBlur={() => setFilterFocus(false)}
        />
        {hasQueries && (
          <Button onClick={() => history.push("/queries/add")} type="primary">
            <PlusOutlined />
            Add query
          </Button>
        )}
      </div>
      <WhiteBox>
        {!hasQueries && (
          <div style={{ textAlign: "center" }}>
            <Button onClick={() => history.push("/queries/add")} type="primary">
              <PlusOutlined />
              Add your first query
            </Button>
          </div>
        )}
        <div className={"queries"}>
          {rows.map((q, idx) => {
            let className = "query-row";
            if (idx < rows.length - 1) {
              className += " not-last";
            }
            if (filterFocus && filter.length > 1 && idx === 0) {
              className += " result-highlight";
            }

            const key = `${q.resourceId}-${q.id}`;

            return (
              <div className={className} key={key}>
                <div className="details">
                  <span className="icon">{iconFor(q.resourceKind)}</span>
                  <span
                    onClick={() =>
                      history.push(
                        `/queries/${q.permaslug}/resources/${q.resourcePermaslug}`
                      )
                    }
                    className="path"
                  >
                    <span className="resource-name">
                      {h("resourceName", idx)}
                    </span>
                    <span className="icon">
                      <RightOutlined />
                    </span>
                    <span className="slug">{h("slug", idx)}</span>
                  </span>
                  {queriesDeleting.includes(q.id) ? (
                    <Tooltip title="Deleting">
                      <DeleteOutlined spin />
                    </Tooltip>
                  ) : (
                    <Tooltip placement="left" title="Delete">
                      <Popconfirm
                        title="Delete? Cannot be undone."
                        onConfirm={() => handleDelete(q.id, q.resourceKind)}
                      >
                        <span className="delete">
                          <DeleteOutlined />
                        </span>
                      </Popconfirm>
                    </Tooltip>
                  )}
                </div>
                <pre className="preview">
                  <code>{h("queryPreview", idx)}</code>
                </pre>
              </div>
            );
          })}
        </div>
      </WhiteBox>
      <style jsx>{`
        .header {
          display: grid;
          grid-template-columns: 1fr auto;
          margin-bottom: 10px;
        }
        .header h1 {
          grid-column: 1 / span 2;
        }
        .query-row {
          padding: 15px;
        }
        .query-row.not-last {
          border-bottom: 1px solid #f0f0f0;
        }
        .query-row.result-highlight {
          background-color: #f0f5ff;
        }
        .query-row .icon {
          font-size: 1.2em;
        }
        .details {
          display: grid;
          grid-template-columns: auto 1fr auto auto auto;
          grid-column-gap: 10px;
        }
        .delete {
          cursor: pointer;
        }
        .path {
          display: grid;
          grid-template-columns: auto auto 1fr;
          align-items: center;
          justify-content: left;
          grid-column-gap: 3px;
          cursor: pointer;
        }
        .path:hover {
          text-decoration: underline;
        }
        .path .icon {
          font-size: 0.8em;
        }
        .resource-name {
          font-weight: 600;
        }
        .preview {
          font-family: monospace;
          padding: 5px;
          border-radius: 5px;
          background-color: #fafafa;
          margin: 3px 15px;
          max-width: 510px;
        }
        .drill-icon {
          grid-column: 2;
          grid-row: 1 / span 2;
        }
        :global(span.inline-highlight) {
          background-color: #fffb8f;
        }
      `}</style>
    </MainLayout>
  );
}

let iconFor = (k: IResourceKind) => {
  if (k === ResourceKind.Values.db) {
    return <DatabaseOutlined />;
  } else {
    return <CloudUploadOutlined />;
  }
};

interface Query {
  id: string;
  permaslug: string;
  resourceId: string;
  resourceName: string;
  resourceKind: IResourceKind;
  resourcePermaslug: string;
  queryPreview: string;
  slug: string;
  insertedAt: Moment;
}

let normalize = (q: { http: HttpQuery[]; db: DbQuery[] }): Query[] => {
  let q1 = q.http.map((q) => {
    let paramNames = q.queryParams.map((q) => q.name);
    let queryParamPreview =
      paramNames.length > 1 ? `{ ${paramNames.join(", ")} }` : "";
    let pathPreview = q.path.match(/^\//) ? q.path : "/" + q.path;
    let queryPreview = `${q.method} ${pathPreview}\n${queryParamPreview}`;
    return {
      id: q.id,
      resourceId: q.resource.id,
      resourceName: q.resource.name,
      resourceKind: ResourceKind.Values.http as IResourceKind,
      resourcePermaslug: q.resource.permaslug,
      slug: q.slug,
      permaslug: q.permaslug,
      queryPreview,
      insertedAt: q.insertedAt,
    };
  });
  let q2 = q.db.map((q) => {
    return {
      id: q.id,
      resourceId: q.resource.id,
      resourceName: q.resource.name,
      resourceKind: ResourceKind.Values.db as IResourceKind,
      resourcePermaslug: q.resource.permaslug,
      slug: q.slug,
      permaslug: q.permaslug,
      queryPreview: q.statement,
      insertedAt: q.insertedAt,
    };
  });
  return q1
    .concat(q2)
    .sort((a, b) => a.insertedAt.valueOf() - b.insertedAt.valueOf());
};

import React from "react";
import ErrorPage from "../pages/ErrorPage";
import { Redirect } from "react-router-dom";
import { ForbiddenError, NotAuthorizedError } from "../api";
import ForbiddenPage from "./ForbiddenPage";

interface State {
  error?: Error | NotAuthorizedError;
  msg?: string;
}

export default class ErrorBoundary extends React.Component<{}, State> {
  state: State = {
    error: undefined,
    msg: undefined
  };

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { error, msg: error.toString() };
  }

  componentDidCatch() {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (!this.state.error) {
      return this.props.children;
    }

    if (this.state.error instanceof NotAuthorizedError) {
      return <Redirect to="/login" />;
    }

    if (this.state.error instanceof ForbiddenError) {
      return <ForbiddenPage />;
    }

    return <ErrorPage msg={this.state.msg} />;
  }
}

import React from "react";
import LocalMessage from "./LocalMessage";
import copy from "clipboard-copy";
import { CopyOutlined } from "@ant-design/icons";

export default function CopyIcon({ content }: { content: string }) {
  return (
    <LocalMessage disappearAfter={1500}>
      {(msg) => (
        <CopyOutlined
          style={{ marginLeft: "5px" }}
          onClick={() => {
            copy(content);
            msg("Copied");
          }}
        />
      )}
    </LocalMessage>
  );
}

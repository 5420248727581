import {
  FieldValidationError,
  BaseServerFieldError,
  ValidationFieldErrors,
  ApiError,
  AntdFieldValidationError
} from "../api/types";

/**
 * This helper converts Ecto validation errors to Antd compatible form errors,
 * so you can show them inside of the form.
 *
 * Check tests for behavior.
 *
 * @param inputErrors
 * @param fieldMap
 */
export function normalizeErrorsForFormAntd(apiError: ApiError): AntdFieldValidationError[] {
  // added to satisfy the compiler
  if (!apiError.error.validation_errors) return [];

  let validationErrors = apiError.error.validation_errors;
  return Object.keys(validationErrors).flatMap((fieldName) =>
    convertErrors(fieldName, validationErrors[fieldName])
  );
}

function convertErrors(
  fieldName: string,
  fieldErrors: ValidationFieldErrors,
  parentField?: string | string[]
): AntdFieldValidationError[] {
  let name = getFieldName(fieldName, parentField);
  // here to properly map the errors we have to distinguish between different types of fields:
  // - fields (e.g. name="name")
  // - nested field (e.g. name={["connection", "host"]})
  // - repeatable fields(e.g. name={["users", index, "name"]})

  // if errors are an array of strings, it's a regular field
  let isField =
    Array.isArray(fieldErrors) && typeof fieldErrors[0] === "string";

  // if it's a nested object then it means it's a nested form field
  let isNestedField = typeof fieldErrors === "object";

  // if it's an array of objects then it means it's a repeatable field
  let isRepeatableField =
    Array.isArray(fieldErrors) && typeof fieldErrors[0] === "object";

  if (isField) {
    return [{
      name: name,
      errors: fieldErrors as string[],
    }];
  } else if (isNestedField) {
    let errors = Object.keys(fieldErrors)
      .flatMap((fieldName) =>
        convertErrors(fieldName, (fieldErrors as BaseServerFieldError)[fieldName], name)
      );
    return errors;
  } else if (isRepeatableField) {
    let errors = (fieldErrors as BaseServerFieldError[])
      .flatMap((repeatableFieldErrors, index) =>
        convertErrors(`${index}`, repeatableFieldErrors, name)
      );
    return errors;
  } else {
    // shouldn't end up here
    return [];
  }
}

function getFieldName(
  fieldName: string,
  parentName?: string | string[]
): string | string[] {
  if (!parentName) {
    return fieldName;
  } else if (typeof parentName === "string") {
    return [parentName, fieldName];
  } else {
    return [...parentName, fieldName];
  }
}

// React Hook Form

/**
 * Method converts the errors returned from backend (Ecto changeset errors)
 * and perpares them to be easily consumed by the react-hook-form forms.
 */
export function normalizeErrorsForForm(
  apiError: ApiError
): FieldValidationError[] {
  // added to satisfy the compiler
  if (!apiError.error.validation_errors) return [];

  let validationErrors = apiError.error.validation_errors;
  return Object.keys(validationErrors).flatMap((fieldName) =>
    normalizeError(fieldName, validationErrors[fieldName])
  );
}

function normalizeError(
  fieldName: string,
  fieldErrors: ValidationFieldErrors,
  parentField?: string
): FieldValidationError[] {
  let name = normalizeFieldName(fieldName, parentField);

  // here to properly map the errors we have to distinguish between different types of fields:
  // - fields (e.g. name="name")
  // - nested field (e.g. name="connection.host")
  // - repeatable fields(e.g. name={`users.${index}.name`})

  // if it's an array of strings, it's a field
  // Note: we can do a fieldErrors.every(e => typeof e === "string") here to satisfy the compiler but that might be a bit too much
  let isField =
    Array.isArray(fieldErrors) && typeof fieldErrors[0] === "string";

  // if it's a nested object then it means it's a nested form
  let isNestedForm = typeof fieldErrors === "object";

  // if it's a repeatble field, then it's an array of objects
  let isRepeatableForm =
    Array.isArray(fieldErrors) && typeof fieldErrors[0] === "object";

  if (isField) {
    return [
      {
        name: name,
        errors: fieldErrors as string[],
      },
    ];
  } else if (isNestedForm) {
    let errors = Object.keys(fieldErrors).flatMap((fieldName) =>
      normalizeError(
        fieldName,
        (fieldErrors as BaseServerFieldError)[fieldName],
        name
      )
    );
    return errors;
  } else if (isRepeatableForm) {
    let errors = (fieldErrors as BaseServerFieldError[]).flatMap(
      (repeatableFieldErrors, index: number) =>
        normalizeError(`${index}`, repeatableFieldErrors, name)
    );
    return errors;
  } else {
    // shouldn't end up here, but let's have it just in case
    return [];
  }
}

function normalizeFieldName(fieldName: string, parentFieldName?: string) {
  if (parentFieldName) {
    return `${parentFieldName}.${fieldName}`;
  } else {
    return fieldName;
  }
}

import React, { useState, useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { Skeleton } from "antd";

import BarebonesLayout from "../components/BarebonesLayout";
import { exchangeLoginCodeForToken, AuthProvider } from "../api";
import ErrorPage from "./ErrorPage";
import { useQueryParams } from "../util/util";

enum Steps {
  ExchangingCode,
  Error,
  Redirect,
}

export default function LoginCallback() {
  let [step, setStep] = useState(Steps.ExchangingCode);
  let [error, setError] = useState<string>();
  let { code } = useQueryParams();
  let { pathname } = useLocation();

  useEffect(() => {
    let doExchange = async () => {
      if (!code) {
        setError("Expected `code` to be present in params, but was not found.");
        return;
      }

      try {
        await exchangeLoginCodeForToken(code as string, AuthProvider.google);
      } catch (e) {
        setError(e.toString());
      }
      setStep(Steps.Redirect);
    };
    // This effectively strips the code from the URL
    window.history.pushState("", "", pathname);
    doExchange();
  }, [code, pathname]);

  if (error) {
    console.error(error);
    return <ErrorPage msg={error} />;
  }

  let processing = step === Steps.ExchangingCode;

  return (
    <BarebonesLayout>
      <div className="header">Login</div>
      {processing ? <Skeleton active /> : <Redirect to="/queries" />}
    </BarebonesLayout>
  );
}

import React from "react";
import { Button, Dropdown, Menu } from "antd";
import CalloutBubble from "./CalloutBubble";
import { EditOutlined, SaveOutlined } from "@ant-design/icons";

export enum BtnState {
  loading,
  disabled,
}

interface Props {
  states: {
    cancel?: BtnState;
    test?: BtnState;
    save?: BtnState;
  };
  onSaveClick(): void;
  onForceSaveClick?(): void;
  onTestClick?(): void;
  onTestEditClick?(): void;
  onCancelClick(): void;
  calloutComponent?: string;
}

type Btn = keyof Props["states"];

export default function EditQueryActionButtons(props: Props) {
  let {
    states,
    onSaveClick,
    onForceSaveClick,
    onTestClick,
    onTestEditClick,
    onCancelClick,
    calloutComponent,
  } = props;

  let getStateProps = (btn: Btn) => {
    return {
      disabled: states[btn] === BtnState.disabled,
      loading: states[btn] === BtnState.loading,
    };
  };

  let forceSaveMenu = () => (
    <Menu onClick={onForceSaveClick}>
      <Menu.Item key="1">Skip tests & save</Menu.Item>
    </Menu>
  );

  return (
    <div className="action-buttons">
      <div style={{ gridArea: "cancel" }}>
        <Button
          {...getStateProps("cancel")}
          onClick={onCancelClick}
          style={{ justifySelf: "flex-start" }}
          type="ghost"
        >
          Cancel
        </Button>
      </div>
      {onTestClick && (
        <div style={{ gridArea: "test" }}>
          <CalloutBubble active={calloutComponent === "test-query"}>
            <Button.Group className="test-save-btn-grp">
              <Button {...getStateProps("test")} onClick={onTestClick}>
                Test
              </Button>
              {onTestEditClick && (
                <Button
                  {...getStateProps("test")}
                  onClick={onTestEditClick}
                  style={{ padding: "5px" }}
                >
                  <EditOutlined />
                </Button>
              )}
            </Button.Group>
          </CalloutBubble>
        </div>
      )}
      <div className="save-btn">
        <CalloutBubble active={calloutComponent === "save-query"}>
          {onForceSaveClick ? (
            <Dropdown.Button
              {...getStateProps("save")}
              type="primary"
              onClick={onSaveClick}
              overlay={forceSaveMenu}
            >
              Save
            </Dropdown.Button>
          ) : (
            <Button
              {...getStateProps("save")}
              onClick={onSaveClick}
              type="primary"
            >
              Save
            </Button>
          )}
        </CalloutBubble>
      </div>
      <style jsx>{`
        :global(.test-save-btn-grp) {
          display: grid;
          grid-template-columns: 1fr auto;
        }
        .save-btn {
          grid-area: save;
        }
        .save-btn :global(.ant-btn-group) {
          display: grid;
          grid-template-columns: 1fr auto;
        }
        .action-buttons {
          display: grid;
          margin-top: 15px;
          grid-column-gap: 10px;
          grid-row-gap: 10px;
          grid-template-areas: "cancel spacer test save";
          grid-template-columns: auto 1fr auto auto;
          justify-content: right;
        }

        @media (min-width: 650px) {
          .action-buttons {
            grid-template-columns: 100px 1fr 100px 100px;
          }
          .action-buttons button {
            width: 100%;
          }
        }
      `}</style>
    </div>
  );
}

import React, { ReactElement, useMemo } from "react";
import { ErrorMessage } from "@hookform/error-message";
import get from "lodash/get";

type Label = string | ReactElement;

interface FormItemProps {
  label: Label;
  required?: boolean;
  help?: string;
  errors: object;
}

export let FormItem: React.FC<FormItemProps> = ({
  required,
  label,
  help,
  errors,
  children,
}) => {
  let name = useMemo<string>(() => findInputName(children), [children]);

  let classes = get(errors, name)
    ? "form-item form-item-has-error"
    : "form-item";

  return (
    <div className={classes}>
      <Label label={label} required={required} />
      {help && <p className="help">{help}</p>}
      {children}
      <Error name={name} errors={errors} />
      <style jsx>{`
        .form-item {
          margin: 0 0 24px;
        }
        .form-item-has-error input,
        .form-item-has-error :global(.ant-input) {
          border-color: #ff4d4f;
        }
        .label {
          font-weight: 500;
        }
        .help {
          color: rgb(140, 140, 140);
        }
      `}</style>
    </div>
  );
};

interface LabelProps {
  label: Label;
  required?: boolean;
}

function Label({ label, required }: LabelProps): ReactElement {
  let styles = (
    <style jsx>{`
      .label-wrapper {
        padding: 0 0 8px;
      }
    `}</style>
  );

  if (typeof label === "string") {
    return (
      <div className="label-wrapper">
        <RequiredAsterisk required={required} />
        <span className="label">{label}</span>
        {styles}
      </div>
    );
  } else {
    return (
      <div className="label-wrapper">
        <RequiredAsterisk required={required} />
        {label}
        {styles}
      </div>
    );
  }
}

interface ErrorProps {
  errors?: object;
  name: string;
}

function Error({ errors, name }: ErrorProps) {
  console.log("FormItem.Error errors", errors);
  return (
    <>
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <div className="error">{message}</div>}
      />
      <style jsx>
        {`
          .error {
            color: #ff4d4f;
          }
        `}
      </style>
    </>
  );
}

interface RequiredAsteriskProps {
  required?: boolean;
}

function RequiredAsterisk({ required }: RequiredAsteriskProps) {
  if (!required) return null;

  return (
    <>
      <span className="required-asteriks">*</span>
      <style jsx>{`
        .required-asteriks {
          display: inline-block;
          margin-right: 4px;
          color: #ff4d4f;
          font-size: 14px;
          font-family: SimSun, sans-serif;
          line-height: 1;
        }
      `}</style>
    </>
  );
}

function findInputName(children: React.ReactNode): string {
  let inputName;
  React.Children.forEach(children, (element) => {
    if (!React.isValidElement(element)) return;

    if (element.props.name) {
      inputName = element.props.name;
    }
  });
  return inputName || "";
}

import { FetchError, ForbiddenError, NotAuthorizedError } from ".";

type fetcherArgs = [string] | [string, RequestInit];
async function fetcher(...args: fetcherArgs) {
  let path = args[0];
  let opts = args[1];
  let method = opts?.method || "GET";
  let defaultHeaders: { [k: string]: string } = [
    "POST",
    "PATCH",
    "PUT",
  ].includes(method)
    ? { "Content-Type": "application/json" }
    : { Accept: "application/json" };

  let res = await fetch(getUrl(path), {
    credentials: "include",
    method,
    ...opts,
    headers: {
      ...defaultHeaders,
      ...opts?.headers,
    },
  });
  if (!res.ok) {
    if (res.status === 401) throw new NotAuthorizedError(res.statusText);
    if (res.status === 403) throw new ForbiddenError(res.statusText);
    else throw new FetchError(res.statusText, res.status);
  }
  return await res.json();
}

// let onError: ConfigInterface["onError"] = (err, key, config) => {
//   if (err instanceof NotAuthorizedError) {
//     message.error("You need to log in first.");
//     window.history.pushState({}, "", "/login");
//   } else {
//     throw err;
//   }
// };

let getUrl = (s: string) => {
  return [process.env.REACT_APP_API_HOST, s.replace(/^\//, "")].join("/");
};

let config = { fetcher, suspense: true };
export default config;

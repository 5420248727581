import React from "react";
import MainLayout, { WhiteBox } from "../components/MainLayout";
import BarebonesLayout from "../components/BarebonesLayout";

export default function ErrorPage({ msg }: { msg?: string }) {
  return (
    <BarebonesLayout>
      <div className="header">
        <h1>Uh oh</h1>
      </div>
      <WhiteBox>
        Something went wrong. We've probably been notified, but feel free to let
        us know.
        <p className="message">Msg: {msg}</p>
      </WhiteBox>
      <style jsx>{`
        .header {
          display: grid;
          grid-template-columns: 1fr auto;
          margin-bottom: 10px;
        }
        .message {
          font-family: monospace;
        }
      `}</style>
    </BarebonesLayout>
  );
}

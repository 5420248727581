import React from "react";
import { Menu, Layout, notification } from "antd";
import {
  CodeOutlined,
  ApiOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  DeploymentUnitOutlined,
  UserOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { useHistory, useLocation } from "react-router-dom";
import { useOrg } from "../api/hooks";

let { Sider } = Layout;

export default function SideNav({ onHide }: { onHide(): void }) {
  let history = useHistory();
  let { pathname } = useLocation();
  let { org } = useOrg({ suspense: false });
  let m = pathname.match(/^\/(\w+)\/?/);
  let activeKey = m && m[1];

  let getClassName = (itemName: string) => {
    if (activeKey === itemName) {
      return "item active";
    }
    return "item";
  };

  function showComingSoonPopup() {
    notification.destroy();
    notification.open({
      message: "Coming soon",
      description: (
        <div>
          Hang tight! This feature is coming soon. For help,{" "}
          <a href="mailto:founders@usedecode.com">contact us</a>.
        </div>
      ),
      duration: 5,
    });
  }

  return (
    <Sider className="dec-sidenav">
      <div className="menu">
        <div className="items">
          <div className="item org">{org?.name}</div>
          <div
            onClick={() => history.push("/queries")}
            className={getClassName("queries")}
          >
            <CodeOutlined />
            Queries
          </div>
          <div
            onClick={() => history.push("/resources")}
            className={getClassName("resources")}
          >
            <ApiOutlined /> Resources
          </div>
          <div
            onClick={showComingSoonPopup}
            className={getClassName("environments")}
          >
            <DeploymentUnitOutlined /> Environments
          </div>
          <div onClick={showComingSoonPopup} className={getClassName("users")}>
            <UserOutlined /> Users
          </div>
          <div
            onClick={showComingSoonPopup}
            className={getClassName("settings")}
          >
            <SettingOutlined /> Settings
          </div>
        </div>
        <div onClick={() => onHide()} className="item hide-sidebar">
          <DoubleLeftOutlined />
          Hide sidebar
        </div>
      </div>
      <style jsx>{`
        :global(.dec-sidenav) {
          background-color: #fafafa;
        }
        .menu {
          border-right: 1px solid #e5e5e5;
          height: 100%;
          display: grid;
          grid-template-rows: 1fr auto;
        }
        .item {
          padding: 5px 25px;
          color: #707070;
          font-size: 1.1em;
          display: grid;
          grid-template-columns: 35px auto;
          align-items: center;
          cursor: pointer;
        }
        .item.active {
          background-color: #e7e7e7;
          color: #694d75;
          border-right: 1px solid #694d75;
        }
        .item.org {
          text-align: center;
          padding: 25px 25px;
          grid-template-columns: 1fr;
        }
        .item.hide-sidebar {
          padding-bottom: 10px;
        }
      `}</style>
    </Sider>
  );
}

export let UnhideSidebarButton = ({ onClick }: { onClick(): void }) => {
  return (
    <div onClick={onClick} className="unhide">
      <DoubleRightOutlined />
      <style jsx>{`
        .unhide {
          background-color: #e5e5e5;
          border-radius: 40px;
          border: 1px solid #e5e5e5;
          color: #707070;
          cursor: pointer;
          position: fixed;
          bottom: 15px;
          left: 15px;
          height: 40px;
          width: 40px;
          display: grid;
          align-items: center;
        }
      `}</style>
    </div>
  );
};

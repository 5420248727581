import React, { ReactElement } from "react";
import { Form, Input, Button } from "antd";

type FieldMap = { [k: string]: string };

interface Props {
  fieldValues: FieldMap;
  fieldNames: string[];
  onSaveAndTest(f: FieldMap): void;
}

export default function EditTestFields({
  fieldValues: initFieldValues,
  fieldNames,
  onSaveAndTest,
}: Props): ReactElement {
  return (
    <Form initialValues={initFieldValues} onFinish={onSaveAndTest}>
      {fieldNames.map((fieldName) => (
        <Form.Item
          label={fieldName}
          name={fieldName}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
      ))}

      <div className="form-actions">
        <Button
          style={{ width: "120px", gridArea: "saveAndTest" }}
          type="primary"
          htmlType="submit"
        >
          Save & Test
        </Button>
      </div>
      <style jsx>{`
        .form-actions {
          display: grid;
          grid-template-columns: 1fr auto auto;
          grid-template-areas: "spacer save saveAndTest";
          grid-column-gap: 10px;
        }
      `}</style>
    </Form>
  );
}

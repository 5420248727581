import React from "react";
import { Collapse, Tabs } from "antd";
import { Controlled as CodeMirror } from "react-codemirror2";

import CopyIcon from "./CopyIcon";

let { TabPane } = Tabs;

type RequestKind = "write" | "read";

interface Props {
  url: string;
  slug: string;
  kind: RequestKind;
  variables: string[];
}

export default function ExecutionDetails(props: Props) {
  let { url, slug, variables, kind } = props;

  return (
    <div className="ExecutionDetails">
      <Tabs type="card">
        <TabPane tab="React" key="1">
          {kind === "write" ? (
            <SampleWriteReactComponent slug={slug} variables={variables} />
          ) : (
            <SampleReadReactComponent slug={slug} variables={variables} />
          )}
        </TabPane>
        <TabPane tab="curl" key="2">
          <SampleCurlRequest url={url} variables={variables} kind={kind} />
        </TabPane>
      </Tabs>
    </div>
  );
}

const SampleCurlRequest = ({
  url,
  variables,
  kind,
}: {
  url: string;
  variables: string[];
  kind: RequestKind;
}) => {
  let cmd = "";
  let header = `-H "Authorization: Bearer {{your-token}}" -H "Content-Type: application/json"`;
  if (variables.length > 0) {
    let o: { [k: string]: any } = {};
    variables.forEach((v) => (o[v] = ""));
    if (kind === "write") {
      o["body"] = {};
    }

    cmd = `curl -X POST -d '${JSON.stringify(o)}' ${url} ${header}`;
  } else {
    cmd = `curl -X POST ${url} ${header}`;
  }
  return (
    <div className="curl-cmd" style={{}}>
      <pre>{cmd}</pre>
      <CopyIcon content={cmd} />
      <style jsx>{`
        pre {
          background-color: #f0f5ff70;
          border-radius: 5px;
          padding: 0px 6px;
          border: 1px solid #e1e3e5;
          overflow-y: hidden;
          overflow-x: auto;
          white-space: pre-wrap;
          margin-bottom: 0px;
        }
        .curl-cmd {
          min-width: 0px;
          display: grid;
          grid-template-columns: 1fr auto;
          align-items: center;
        }
      `}</style>
    </div>
  );
};

const SampleWriteReactComponent = ({
  slug,
  variables,
}: {
  slug: string;
  variables: string[];
}) => {
  let paramsFirstHalf: string;
  if (variables.length > 0) {
    paramsFirstHalf = `{ ${variables.join(", ")},`;
  } else {
    paramsFirstHalf = `{`;
  }

  let codeString = `
import React from "react";
import { useDecode, useFetcher } from "@decode/client";

let MyComponent = ({ ${variables.join(", ")} }) => {
  let fetcher = useFetcher();

  let handleSubmit = () => {
    fetcher("${slug}", ${paramsFirstHalf} body: {
        /* your body params here */ 
      }
    });
  }

  /* render your component */
};

export default MyComponent;
`;
  return (
    <div style={{ minWidth: "0px" }}>
      <CodeMirror
        onBeforeChange={() => {}}
        options={{
          readOnly: true,
          mode: "javascript",
          theme: "default-ro",
          scrollbarStyle: "null",
        }}
        value={codeString}
      />
    </div>
  );
};

const SampleReadReactComponent = ({
  slug,
  variables,
}: {
  slug: string;
  variables: string[];
}) => {
  let codeString;
  if (variables.length > 0) {
    let variableList = variables.join(", ");
    codeString = `
import React, { useMemo } from "react";
import { useDecode } from "@decode/client";

let MyComponent = ({ ${variableList} }) => {
  let params = useMemo(() => ({ ${variableList} }), [${variableList}]);
  let { data, error } = useDecode(["${slug}", params])

  /* render your component */
};

export default MyComponent;
`;
  } else {
    codeString = `
import React from "react";
import { useDecode } from "@decode/client";

let MyComponent = () => {
  let { data, error } = useDecode("${slug}");

  /* render your component */
};

export default MyComponent;
    `;
  }
  return (
    <div style={{ minWidth: "0px" }}>
      <CodeMirror
        onBeforeChange={() => {}}
        options={{
          readOnly: true,
          mode: "javascript",
          theme: "default-ro",
          scrollbarStyle: "null",
        }}
        value={codeString}
      />
    </div>
  );
};

// .variables {
//           display: grid;
//           width: 100%;
//           grid-template-columns: 1fr 1fr;
//           grid-row-gap: 10px;
//           grid-column-gap: 15px;
//         }

//         .table-header {
//           background-color: #fafafa;
//           border-bottom: 1px solid #e8e8e8;
//           font-weight: 500;
//           display: grid;
//           grid-template-columns: 1fr 1fr;
//           grid-column: 1 / span 2;
//           height: 2.5em;
//           align-items: center;
//           <div className="variables" style={{}}>
//           {numVars === 0 ? (
//             <div>No variables in this query</div>
//           ) : (
//             <>
//               <div className="table-header">
//                 <div className="col">Name</div>
//                 <div className="col">Where?</div>
//               </div>
//               {variables.mapv name, where, v }, idx) => {
//                 let className =
//                   provider === "caller" ? "inline-var" : "inline-context-var";
//                 return (
//                   <React.Fragment key={idx}>
//                     <div className="col">
//                       <span className={className}>{`{{${name}}}`}</span>
//                     </div>
//                     <div className="col">{where}</div>
//                   </React.Fragment>
//                 );
//               })}
//             </>
//           )}
//         </div>

import React, { useState, useEffect, ReactElement } from "react";
import { Collapse } from "antd";
import { LoadingOutlined, RightOutlined } from "@ant-design/icons";
import { TestResult } from "../api";

interface Props {
  testInProgress: boolean;
  testResult?: TestResult;
  error?: string;
}

export default function TestResultView({
  testResult,
  testInProgress,
  error,
}: Props): ReactElement {
  let [resultExpanded, setResultExpanded] = useState(false);

  useEffect(() => {
    if (testResult?.ok === false) {
      setResultExpanded(true);
    }
    return () => {};
  }, [testResult]);

  const getTestResultHeader = () => {
    if (testInProgress) {
      return (
        <>
          <LoadingOutlined /> Result
        </>
      );
    } else if (testResult) {
      if (testResult.ok) {
        return "✅ Result";
      } else {
        return "❌ Result";
      }
    }

    return "Result";
  };

  let showTestResult = testResult !== undefined || testInProgress === true;
  return (
    <>
      {showTestResult && (
        <Collapse
          bordered={false}
          activeKey={resultExpanded ? ["1"] : []}
          onChange={(keys) =>
            keys.length > 0 ? setResultExpanded(true) : setResultExpanded(false)
          }
          expandIcon={({ isActive }) => (
            <RightOutlined rotate={isActive ? 90 : 0} />
          )}
        >
          <Collapse.Panel
            header={getTestResultHeader()}
            key="1"
            className="test-result-panel"
          >
            {testResult && (
              <pre className="result">
                <code>{testResult.log}</code>
              </pre>
            )}
          </Collapse.Panel>
        </Collapse>
      )}

      {error && <p className="error">{error}</p>}

      <style jsx>{`
        .result {
          max-height: 300px;
          max-width: 500px;
          overflow-y: auto;
          overflow-x: auto;
          word-break: break-word;
          white-space: pre-wrap;
        }

        .test-result-panel {
          background: #f7f7f7;
          border-radius: 4;
          margin-bottom: 24;
          border: 0;
          overflow: hidden;
        }

        .error {
          text-align: right;
          color: #f5222d;
          margin: 0px;
        }
      `}</style>
    </>
  );
}

import React from "react";
import { Divider } from "antd";

import { RequestMethod } from "../api/types";

import "./animations.css";
import ExecutionDetails from "./ExecutionDetails";
import QueryValue from "./QueryValue";
import CopyIcon from "./CopyIcon";
import * as Variables from "../util/variables";
import { HttpQuery as IHttpQuery } from "../api/types";

interface Props {
  query: IHttpQuery;
}

export default function HttpQuery(props: Props) {
  let { slug, resource, method, path, queryParams } = props.query;
  let url = getInvokeUrl(slug);
  let variables = Variables.extractCaller(path).concat(
    queryParams.map((q) => q.name)
  );

  return (
    <>
      <h3 className="field-label">Resource</h3>
      <div className="field-content">{resource.name}</div>
      <h3 className="field-label">Endpoint</h3>
      <div className="field-content">
        {url}
        <CopyIcon content={url} />
      </div>
      <h3 className="field-label">Action & Path</h3>
      <div
        className="field-content"
        style={{ display: "grid", gridTemplateColumns: "120px 1fr" }}
      >
        <span className="request-method">{method}</span>
        <div style={{ minWidth: "0px" }}>
          <QueryValue value={path} />
        </div>
      </div>
      <h3 className="field-label">Query Params</h3>
      <div className="field-content query-params">
        <div className="col-header">Name</div>
        <div className="col-header">Required?</div>
        {queryParams.map(({ name, required }, idx) => (
          <React.Fragment key={idx}>
            <div style={{ minWidth: "70px", width: "100%" }}>
              <span>{name}</span>
            </div>
            {required ? "Yes" : "No"}
          </React.Fragment>
        ))}
      </div>
      <Divider orientation="left">Execution</Divider>
      <ExecutionDetails
        kind={
          [
            RequestMethod.Values.PUT,
            RequestMethod.Values.PATCH,
            RequestMethod.Values.POST,
            RequestMethod.Values.DELETE,
          ].includes(method)
            ? "write"
            : "read"
        }
        slug={slug}
        url={url}
        variables={variables}
      />
      <style jsx>{`
        h3.field-label {
          font-weight: 600;
          font-size: 1em;
        }
        .endpoint-slug {
          max-width: 450px;
        }
        .headers {
          max-width: 450px;
        }
        .url {
          display: grid;
          grid-template-columns: 1fr auto;
          grid-column-gap: 10px;
          align-items: center;
          margin: 5px 0px;
          background-color: #fafafa;
          /* background-color: #fffbe6; */
          padding: 7px;
          border-radius: 5px;
        }
        .headers {
          display: grid;
        }
        .query-params {
          display: grid;
          grid-column-gap: 7px;
          grid-row-gap: 7px;
          grid-template-columns: auto 1fr;
          align-items: center;
          justify-items: left;
        }
        .url span {
          overflow-x: hidden;
        }

        .field-content {
          margin-bottom: 15px;
          font-family: "Roboto Mono", Courier, monospace;
        }

        .col-header {
          font-weight: 600;
          text-transform: capitalize;
          font-size: 0.8em;
        }

        .field-content i {
          color: #8c8c8c;
        }
      `}</style>
    </>
  );
}

let getInvokeUrl = (slug: string) => {
  return `https://api.usedecode.com/e/${slug}`;
};

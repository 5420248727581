import React from "react";
import { Controller, UseFormMethods } from "react-hook-form";
import { Select as AntSelect } from "antd";
import { SelectProps as AntSelectProps } from "antd/lib/select";

type Name = string;

interface SelectProps extends AntSelectProps<any> {
  control: UseFormMethods["control"];
  name: Name;
}

export let Select = ({ control, name, ...rest }: SelectProps) => (
  <Controller
    control={control}
    name={name}
    render={({ onChange, value }) => (
      <AntSelect {...rest} value={value} onChange={onChange} />
    )}
  />
);

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";

import "antd/dist/antd.css";
import "./ant-overrides.css";
import "./index.css";
import "./codemirror/loadCodeMirror";
import { SWRConfig } from "swr";
import swrConfig from "./api/swrConfig";

// Sentry.init({
//   dsn: "https://1842aec436284204ac8a2eedfab9ca44@sentry.io/1820972",
// });

ReactDOM.render(
  <Router>
    <SWRConfig value={swrConfig}>
      <App />
    </SWRConfig>
    <style jsx global>{`
      body {
        background-color: #f0f2f5 !important;
      }
    `}</style>
  </Router>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

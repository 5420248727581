import React from "react";
import { WhiteBox } from "../components/MainLayout";
import BarebonesLayout from "../components/BarebonesLayout";
import { Button } from "antd";
import { Link } from "react-router-dom";

export default function LoggedOut() {
  return (
    <BarebonesLayout>
      <h1 className="header">Logged out</h1>
      <WhiteBox>
        <p>Until next time, stranger.</p>
        <div className="actions">
          <Link to="/login">
            <Button type="primary">Login</Button>
          </Link>
        </div>
      </WhiteBox>

      <style jsx>{`
        .actions {
          display: grid;
          justify-items: center;
        }
        .actions button {
          width: fit-content;
        }
      `}</style>
    </BarebonesLayout>
  );
}

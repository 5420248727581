import React, { useReducer } from "react";
import { ResourceKind, IResourceKind, HttpResource } from "../api/types";

import MainLayout, { WhiteBox } from "../components/MainLayout";
import { useHistory } from "react-router-dom";
import ResourceList from "../components/ResourceList";
import EditDbQueryContainer from "../components/EditDbQueryContainer";
import EditHttpQuery, {
  HttpQueryModifiableParams,
} from "../components/EditHttpQuery";
import * as api from "../api";
import { DbQueryModifiableParams } from "../components/EditDbQuery";
import { useResources, useQueries } from "../api/hooks";

import { message, Button } from "antd";

enum Steps {
  SelectResource,
  AddDb,
  AddHttp,
}

interface State {
  step: Steps;
  resourceId?: string;
}

let getInitialState = (): State => {
  // return {
  //   step: Steps.AddHttp,
  //   resourceId: "2",
  // };
  return {
    step: Steps.SelectResource,
  };
};

let reducer = (state: State, action: ActionObject): State => {
  switch (action.type) {
    case Action.ResourceSelected: {
      let { id, kind } = action;
      if (kind === ResourceKind.Values.db) {
        return {
          ...state,
          resourceId: id,
          step: Steps.AddDb,
        };
      } else {
        return {
          ...state,
          resourceId: id,
          step: Steps.AddHttp,
        };
      }
    }
    case Action.ResourceDeselected: {
      return {
        ...state,
        step: Steps.SelectResource,
        resourceId: undefined,
      };
    }
  }
};

enum Action {
  ResourceSelected,
  ResourceDeselected,
}

type ActionObject =
  | {
      type: Action.ResourceDeselected;
    }
  | {
      type: Action.ResourceSelected;
      id: string;
      kind: string;
    };

export default function AddQuery() {
  let history = useHistory();
  const [state, dispatch] = useReducer(reducer, getInitialState());
  let { step, resourceId } = state;
  let { mutate } = useQueries();
  let { resources } = useResources();

  let { http, db } = resources;

  let resourceList = [...http, ...db];
  let resource = resourceId
    ? resourceList.find((r) => r.id === resourceId)
    : null;
  let resourceName = resource?.name;

  let handleHttpSave = async (params: HttpQueryModifiableParams) => {
    if (!resourceId) {
      throw new Error("Expected `resourceId` to be specified.");
    }

    // TODO - parse this response and just use it?
    let id = await api.createHttpQuery({
      ...params,
      resourceId,
    });
    history.push("/queries");
    mutate();
    message.success("Query created");
  };

  let handleDbSave = async (params: DbQueryModifiableParams) => {
    if (!resourceId) {
      throw new Error("Expected `resourceId` to be specified.");
    }

    let id = await api.createDbQuery({
      ...params,
      resourceId,
    });
    history.push("/queries");
    mutate();
    message.success("Query created");
  };

  return (
    <MainLayout
      navHeader={{
        onBack: () => history.push("/queries"),
        title: "Back to all queries",
      }}
    >
      <div className="header">
        <h1>Add new query</h1>
        {step === Steps.SelectResource ? (
          <h4>Please select a resource</h4>
        ) : (
          <div>
            <span className="selected-resource">{resourceName} </span>
            <Button
              type="ghost"
              onClick={() => dispatch({ type: Action.ResourceDeselected })}
            >
              Change
            </Button>
          </div>
        )}
      </div>
      <WhiteBox>
        {step === Steps.SelectResource && (
          <ResourceList
            resources={resourceList}
            onSelect={({ id }) => {
              let resource = resourceList.find((r) => r.id === id);
              if (!resource)
                throw new Error(`Invalid 'id' - no resource found`);
              dispatch({
                type: Action.ResourceSelected,
                id,
                kind: resource.kind,
              });
            }}
          />
        )}
        {step === Steps.AddDb && (
          <EditDbQueryContainer
            resourceId={resourceId as string}
            onSave={handleDbSave}
            onCancel={() => history.push("/queries")}
          />
        )}
        {step === Steps.AddHttp && (
          <EditHttpQuery
            resource={resource as HttpResource}
            onSave={handleHttpSave}
            onCancel={() => history.push("/queries")}
          />
        )}
      </WhiteBox>
      <style jsx>{`
        .header {
          display: grid;
          grid-template-columns: 1fr auto;
          margin-bottom: 10px;
        }
        .header h1 {
          grid-column: 1 / span 2;
        }
        span.selected-resource {
          color: #595959;
          background-color: #fafafa;
          border: 1px solid #f0f0f0;
          padding: 4px;
          border-radius: 5px;
          margin-right: 7px;
        }
        .query-row {
          padding: 15px;
        }
        .query-row.not-last {
          border-bottom: 1px solid #f0f0f0;
        }
        .query-row.result-highlight {
          background-color: #f0f5ff;
        }
        .query-row .icon {
          font-size: 1.2em;
        }
        .details {
          display: grid;
          grid-template-columns: auto 1fr auto auto auto;
          grid-column-gap: 10px;
        }
        .delete {
          cursor: pointer;
        }
        .path {
          display: grid;
          grid-template-columns: auto auto 1fr;
          align-items: center;
          justify-content: left;
          grid-column-gap: 3px;
          cursor: pointer;
        }
        .path:hover {
          text-decoration: underline;
        }
        .path .icon {
          font-size: 0.8em;
        }
        .resource-name {
          font-weight: 600;
        }
        .preview {
          font-family: monospace;
          padding: 5px;
          border-radius: 5px;
          background-color: #fafafa;
          margin: 3px 15px;
        }
        .drill-icon {
          grid-column: 2;
          grid-row: 1 / span 2;
        }
        :global(span.inline-highlight) {
          background-color: #fffb8f;
        }
      `}</style>
    </MainLayout>
  );
}

interface Query {
  id: string;
  resourceName: string;
  resourceKind: IResourceKind;
  queryPreview: string;
  slug: string;
}

import * as z from "zod";
import { isMoment } from "moment";

export const DbKind = z.enum(["mysql", "postgres", "mssql"]);
export type IDbKind = z.infer<typeof DbKind>;
export const RequestMethod = z.enum([
  "GET",
  "POST",
  "PUT",
  "DELETE",
  "PATCH",
  "ALL",
  "OPTIONS",
  "HEAD",
]);
export type IRequestMethod = z.infer<typeof RequestMethod>;
export const ParameterLocation = z.enum(["path", "query", "header"]);
export const ResourceKind = z.enum(["db", "http"]);
export type IResourceKind = z.infer<typeof ResourceKind>;

const roleSchema = z.object({
  id: z.string(),
  name: z.string(),
});
export type Role = z.infer<typeof roleSchema>;

const organizationSchema = z.object({
  id: z.string(),
  name: z.string(),
  slug: z.string(),
  roles: z.array(roleSchema),
});
export type Organization = z.infer<typeof organizationSchema>;

export const userSchema = z.object({
  id: z.string(),
  name: z.union([z.string(), z.null()]),
  email: z.string(),
  orgId: z.string(),
  insertedAt: z.any(),
});
export type User = z.infer<typeof userSchema>;

const dbConnectionSchema = z.object({
  host: z.string(),
  port: z.number(),
  user: z.string(),
  dbname: z.string(),
  password: z.string().optional(),
});
export type DbConnection = z.infer<typeof dbConnectionSchema>;

const baseResourceSchema = z.object({
  id: z.string(),
  permaslug: z.string(),
  kind: z.union([z.literal("db"), z.literal("http")]),
  name: z.string(),
  orgId: z.string(),
  envId: z.string(),
  insertedAt: z.any(),
  updatedAt: z.any(),
});

const dbResourceSchema = baseResourceSchema.merge(
  z.object({
    connection: dbConnectionSchema,
    kind: z.literal("db"),
    dbKind: DbKind,
  })
);
export type DbResource = z.infer<typeof dbResourceSchema>;
export type DbResourceModifiableParams = Pick<
  DbResource,
  "connection" | "dbKind" | "name"
>;

export const SecuritySchemeKind = z.enum(["apiKey", "bearer", "basic", "none"]);
export type SecuritySchemeKind = z.infer<typeof SecuritySchemeKind>;

const ApiKeyLocation = z.enum(["header", "query", "cookie"]);
export type ApiKeyLocation = z.infer<typeof ApiKeyLocation>;

const apiKeySecuritySchemeSchema = z.object({
  type: z.literal("http"),
  scheme: z.literal(SecuritySchemeKind.Values.apiKey),
  secret: z.string(),
  location: ApiKeyLocation,
  name: z.string(),
});

// Say 5 times fast
const bearerSecuritySchemeSchema = z.object({
  type: z.literal("http"),
  scheme: z.literal(SecuritySchemeKind.Values.bearer),
  secret: z.string(),
});

const basicSecuritySchemeSchema = z.object({
  type: z.literal("http"),
  scheme: z.literal(SecuritySchemeKind.Values.basic),
  user: z.string(),
  secret: z.string(),
});
const noneSecuritySchemeSchema = z.object({
  type: z.literal("http"),
  scheme: z.literal("none"),
});

const httpResourceSchema = baseResourceSchema.merge(
  z.object({
    kind: z.literal("http"),
    definition: z.object({
      url: z.string(),
      securityScheme: z.union([
        apiKeySecuritySchemeSchema,
        bearerSecuritySchemeSchema,
        basicSecuritySchemeSchema,
        noneSecuritySchemeSchema,
      ]),
    }),
  })
);
export type HttpResource = z.infer<typeof httpResourceSchema>;
export type HttpResourceModifiableParams = Pick<
  HttpResource,
  "name" | "definition" | "envId"
>;

let timestampRefinement = (val: unknown) =>
  typeof val === "string" || isMoment(val);

const baseQuerySchema = z.object({
  id: z.string(),
  permaslug: z.string(),
  slug: z.string(),
  orgId: z.string(),
  insertedAt: z.any().refine(timestampRefinement, "must be a string or Moment"),
  updatedAt: z.any().refine(timestampRefinement, "must be a string or Moment"),
  roles: z.array(z.string()),
});
// type BaseQuery = z.infer<typeof baseQuerySchema>

const dbQuerySchema = baseQuerySchema.merge(
  z.object({
    resource: dbResourceSchema,
    statement: z.string(),
    testFields: z.record(z.string()),
  })
);

export type DbQuery = z.infer<typeof dbQuerySchema>;

const httpQuerySchema = baseQuerySchema.merge(
  z.object({
    resource: httpResourceSchema,
    queryParams: z.array(
      z.object({
        name: z.string(),
        required: z.boolean(),
      })
    ),
    path: z.string(),
    method: RequestMethod,
  })
);

export type HttpQuery = z.infer<typeof httpQuerySchema>;
export type TestHttpQuery = Pick<
  HttpQuery,
  "resource" | "method" | "path" | "queryParams"
>;

const envSchema = z.object({
  id: z.string(),
  permaslug: z.string(),
  slug: z.string(),
  name: z.string(),
  orgId: z.string(),
  insertedAt: z.any().refine(timestampRefinement, "must be a string or Moment"),
  updatedAt: z.any().refine(timestampRefinement, "must be a string or Moment"),
});
export type Env = z.infer<typeof envSchema>;

// RESPONSES

const listOrgQueries = z.object({
  ok: z.literal(true),
  queries: z.object({
    http: z.array(httpQuerySchema),
    db: z.array(dbQuerySchema),
  }),
});
export type ListOrgQueries = z.infer<typeof listOrgQueries>;

const listOrgResources = z.object({
  ok: z.literal(true),
  resources: z.object({
    http: z.array(httpResourceSchema),
    db: z.array(dbResourceSchema),
  }),
});
export type ListOrgResources = z.infer<typeof listOrgResources>;

const listOrgEnvs = z.object({
  ok: z.literal(true),
  envs: z.array(envSchema),
});
export type ListOrgEnvs = z.infer<typeof listOrgEnvs>;

const getUserOrg = z.object({
  ok: z.literal(true),
  org: organizationSchema,
});
export type GetUserOrg = z.infer<typeof getUserOrg>;

const getUser = z.object({
  ok: z.literal(true),
  user: userSchema,
});
export type GetUser = z.infer<typeof getUser>;

export const responses = {
  listOrgQueries,
  listOrgResources,
  listOrgEnvs,
  getUserOrg,
  getUser,
};

// Result type and API/Validation error types

let baseServerFieldErrorSchema = z.record(z.array(z.string()));

// with this definition we can only parse errors for forms max. 2 levels deep
// not sure if we can have recursive types here
let validationFieldErrorsSchema = z.union([
  z.array(z.string()),
  baseServerFieldErrorSchema,
  z.array(baseServerFieldErrorSchema),
]);

let validationErrorsSchema = z.record(validationFieldErrorsSchema).optional();

export const apiErrorSchema = z.object({
  ok: z.literal(false),
  who: z.string().optional(),
  error: z.object({
    summary: z.string(),
    validation_errors: validationErrorsSchema,
  }),
});

export type BaseServerFieldError = z.infer<typeof baseServerFieldErrorSchema>;
export type ValidationFieldErrors = z.infer<typeof validationFieldErrorsSchema>;
export type ValidationErrors = z.infer<typeof validationErrorsSchema>;
export type ApiError = z.infer<typeof apiErrorSchema>;

// normalized error types (react-hook-form)
export type FieldValidationError = {
  name: string;
  errors: string[];
};

// normalized error types (Antd)
export type AntdFieldValidationError = {
  name: string | string[];
  errors: string[];
};

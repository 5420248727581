export const ContextRegex = /\{\{(ctx\.\w+)\}\}/g;
export const CallerRegex = /\{\{(\w+)\}\}/g;
const AllRegex = /(\{\{(?:(?:ctx\.)?\w+)\}\})/g;

export interface Variable {
  name: string;
  provider: "caller" | "context";
}

let extractVariables = (regexpStr: RegExp) => (
  s: string | string[]
): string[] => {
  if (typeof s === "string") {
    let regexp = new RegExp(regexpStr);
    let m = regexp.exec(s);
    let matches = new Set<string>();
    while (m != null) {
      matches.add(m[1]);
      m = regexp.exec(s);
    }
    return [...matches];
  } else if (Array.isArray(s)) {
    return s.reduce<string[]>((memo, el) => {
      return memo.concat(extractVariables(regexpStr)(el));
    }, []);
  } else {
    throw new Error(`Invalid type passed to 'extractParams': ${s}`);
  }
};

let extractContext = extractVariables(ContextRegex);
export let extractCaller = extractVariables(CallerRegex);

export let extractAll = (s: string | string[]) => {
  let context = extractContext(s);
  let caller = extractCaller(s);

  return [
    ...context.reduce((memo, name) => {
      return memo.concat({
        name,
        provider: "context",
      });
    }, [] as Variable[]),
    ...caller.reduce((memo, name) => {
      return memo.concat({
        name,
        provider: "caller",
      });
    }, [] as Variable[]),
  ];
};

export let splitString = (s: string) => {
  let a = s.split(AllRegex);
  return a.map((el) => {
    if (el.match(ContextRegex)) {
      return {
        name: el,
        provider: "context",
      };
    } else if (el.match(CallerRegex)) {
      return {
        name: el,
        provider: "caller",
      };
    }
    return el;
  });
};

/**
 * Checks whether the passed SQL statement has any forbidden characters surrounding {{var}} tokens.
 */
export let getInvalidSqlVars = (statement: string) => {
  let sqlParamRegex = /\{\{\w+\}\}/g;
  let result;

  while ((result = sqlParamRegex.exec(statement)) !== null) {
    let [sqlVariable] = result;
    let startIndex = result.index;
    let endIndex = startIndex + sqlVariable.length;

    let stringBeforeVar = statement.substring(0, startIndex);
    let stringAfterVar = statement.substring(endIndex);

    let invalidBefore = invalidCharBeforeSqlVar(stringBeforeVar);
    let invalidAfter = invalidCharAfterSqlVar(stringAfterVar);

    if (invalidBefore && invalidAfter) {
      return `${invalidBefore}${sqlVariable}${invalidAfter}`;
    } else if (invalidBefore) {
      return `${invalidBefore}${sqlVariable}`;
    } else if (invalidAfter) {
      return `${sqlVariable}${invalidAfter}`;
    }
  }

  return null;
};

let invalidCharBeforeSqlVar = (str: string) => {
  if (!str) return null;

  let result = /\S+$/.exec(str);
  if (result) {
    return result[0];
  } else {
    return null;
  }
};
let invalidCharAfterSqlVar = (str: string) => {
  if (!str || str[0] === ";") return null;

  let result = /^\S+/.exec(str);
  if (result) {
    return result[0];
  } else {
    return null;
  }
};

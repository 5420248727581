import React from "react";
import MainLayout, { WhiteBox } from "../components/MainLayout";
import { Skeleton } from "antd";

export default function LoadingPage({ title }: { title?: string }) {
  let h1 = title ?? "Loading";
  return (
    <MainLayout>
      <div className="header">
        <h1>{h1}</h1>
      </div>
      <WhiteBox>
        <Skeleton active />
      </WhiteBox>
      <style jsx>{`
        h1 {
          color: #8c8c8c;
        }
        .header {
          display: grid;
          grid-template-columns: 1fr auto;
          margin-bottom: 10px;
        }
        .message {
          font-family: monospace;
        }
      `}</style>
    </MainLayout>
  );
}

import React, { useEffect, useRef, useReducer } from "react";
import {
  Select,
  Input,
  Checkbox,
  Tag,
  Tooltip,
  Divider,
  Modal,
} from "antd";
import update from "immutability-helper";
import TestHttpQuery from "./TestHttpQuery";

import {
  HttpQuery,
  RequestMethod,
  IRequestMethod,
  HttpResource,
  TestHttpQuery as TestHttpQueryType,
} from "../api/types";

import "./animations.css";
import CodeMirrorInput from "./CodeMirrorInput";
import * as Variables from "../util/variables";
import { Variable } from "../util/variables";
import {
  PlusOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import EditQueryActionButtons from "./EditQueryActionButtons";
import { Moment } from "moment";
import { useOrg } from "../api/hooks";

const { Option } = Select;

export type HttpQueryModifiableParams = Pick<
  HttpQuery,
  "queryParams" | "slug" | "method" | "path" | "roles"
>;
interface Props {
  onCancel(): void;
  onSave(params: HttpQueryModifiableParams, id?: string): void;
  query?: HttpQuery;
  resource: HttpResource;
}

interface State
  extends Omit<
    HttpQuery,
    "resource" | "insertedAt" | "orgId" | "roles" | "updatedAt" | "permaslug"
  > {
  slugEditEnabled: boolean;
  pathVariables: Variable[];
  insertedAt?: Moment;
  roles: string[];
  showTestModal: boolean;
}

let getInitialState = (props: Props): State => {
  let { query: q } = props;
  let pathVariables;
  if (q) {
    pathVariables = Variables.extractAll(q.path);
  }

  return {
    id: q?.id ?? "",
    queryParams: q?.queryParams ?? [emptyQuery],
    slug: q?.slug ?? "",
    slugEditEnabled: false,
    path: q?.path ?? "",
    method: q?.method ?? RequestMethod.Values.GET,
    pathVariables: pathVariables ?? [],
    insertedAt: q?.insertedAt,
    roles: q?.roles ?? [],
    showTestModal: false,
    // resourceId: q?.resource.id ?? props.resourceId
  };
};

export default function EditHttpQuery(props: Props) {
  let [state, dispatch] = useReducer(reducer, getInitialState(props));
  let { org } = useOrg();
  let { queryParams, slug, slugEditEnabled, method, path, roles, showTestModal } = state;
  let { onCancel, onSave } = props;
  let slugInput = useRef<Input>(null);

  useEffect(() => {
    if (slugEditEnabled) {
      slugInput.current?.focus();
    }
  }, [slugEditEnabled]);

  let roleOptions = org
    ? org.roles.map(({ id, name }) => ({
        label: name,
        value: id,
        disabled: false,
      }))
    : [];

  let handleSave = () => {
    onSave(
      {
        queryParams: queryParams.filter((q) => q.name.length > 0),
        slug,
        method,
        path,
        roles,
      },
      props.query?.id
    );
  };

  function handleShowTestModal() {
    dispatch({ type: Actions.TestModalOpen })
  }

  function handleCloseTestModal() {
    dispatch({ type: Actions.TestModalClosed })
  }

  let urlPrefix = `https://api.usedecode.com/e/`;

  let queryParameters = queryParams.map(p => ({...p, location: "query"}));
  let urlParameters = Variables.extractCaller(path)
    .map(paramName => ({
      name: paramName,
      location: "path",
      required: true,
    }));

  let httpQuery : TestHttpQueryType = {
    resource: props.resource,
    method,
    path,
    queryParams,
  }

  return (
    <>
      <Modal
        title="Test HTTP Query"
        onCancel={handleCloseTestModal}
        visible={showTestModal}
        footer={null}
        destroyOnClose
      >
        <TestHttpQuery
          httpQuery={httpQuery}
          resource={props.resource}
          onClose={handleCloseTestModal}
        />
      </Modal>

      <h3 className="field-label">Endpoint on Decode</h3>
      <div className="field endpoint-slug">
        <Input
          addonBefore={urlPrefix}
          value={slug}
          placeholder="listUsers"
          onChange={({ currentTarget: { value } }) =>
            dispatch({ type: Actions.SlugChanged, value })
          }
        />
      </div>
      <Divider />
      <div className="form">
        <div className="field">
          <h3 className="field-label">Action & Path</h3>
          <div
            className="field-content"
            style={{
              display: "grid",
              gridTemplateColumns: "1fr",
              gridRowGap: "7px",
            }}
          >
            <Select
              size={"large"}
              value={method}
              onChange={(value: IRequestMethod) =>
                dispatch({ type: Actions.MethodSelected, method: value })
              }
              style={{ width: "110px" }}
            >
              {Object.keys(RequestMethod.Values).map((m) => (
                <Option key={m} value={m}>
                  {m}
                </Option>
              ))}
            </Select>
            <div style={{ minWidth: "0px" }}>
              <span className="ant-input-group-wrapper">
                <span className="ant-input-wrapper ant-input-group">
                  <span className="ant-input-group-addon">
                    {addTrailingSlash(props.resource.definition.url)}
                  </span>
                  <CodeMirrorInput
                    onBeforeChange={(editor, data, value) => {
                      dispatch({
                        type: Actions.PathChanged,
                        value,
                      });
                    }}
                    mode="simple-decode-vars"
                    value={path}
                    options={{
                      theme: "decode has-left-addon single-line",
                      lineWrapping: true,
                    }}
                  />
                </span>
              </span>
            </div>
          </div>
        </div>

        <div className="field">
          <h3 className="field-label">Query Params</h3>
          <div
            className="field-content"
            style={{ display: "grid", gridRowGap: "7px" }}
          >
            <div className="query-params">
              <div className="col-header">name</div>
              <div className="col-header">Required?</div>
              {queryParams.map(({ name, required }, idx) => (
                <React.Fragment key={idx}>
                  <Input
                    style={{ gridColumn: 1 }}
                    value={name}
                    onChange={(e) =>
                      dispatch({
                        type: Actions.QueryNameChanged,
                        idx,
                        name: e.currentTarget.value,
                      })
                    }
                  />
                  <Checkbox
                    checked={required}
                    onChange={({ target: { checked } }) =>
                      dispatch({
                        type: Actions.QueryRequiredToggled,
                        required: checked,
                        idx,
                      })
                    }
                  />
                  <Tooltip title="Remove">
                    <CloseOutlined
                      onClick={() =>
                        dispatch({ type: Actions.DelQueryClicked, idx })
                      }
                    />
                  </Tooltip>
                </React.Fragment>
              ))}
            </div>
          </div>
          <Tag
            onClick={() => dispatch({ type: Actions.AddQueryClicked })}
            icon={<PlusOutlined />}
            className="add-new"
          >
            add
          </Tag>
        </div>
        <h3 className="field-label">Roles</h3>
        <div className="field roles">
          {roleOptions.length > 0 ? (
            <Checkbox.Group
              options={roleOptions}
              defaultValue={roles}
              onChange={(roles) =>
                dispatch({
                  type: Actions.RolesChanged,
                  roles: roles as string[],
                })
              }
            />
          ) : (
            "No roles have been setup."
          )}
        </div>
      </div>
      <EditQueryActionButtons
        states={{}}
        onTestClick={handleShowTestModal}
        onCancelClick={onCancel}
        onSaveClick={handleSave}
      />

      <style jsx>{`
        h3.field-label {
          font-weight: 600;
          font-size: 1em;
        }
        .endpoint-slug {
          max-width: 450px;
        }
        .headers {
          max-width: 450px;
        }
        .url {
          display: grid;
          grid-template-columns: 1fr auto;
          grid-column-gap: 10px;
          align-items: center;
          margin: 5px 0px;
          background-color: #fafafa;
          padding: 7px;
          border-radius: 5px;
        }
        :global(.add-new) {
          margin-top: 5px;
          cursor: pointer;
        }
        .query-params {
          display: grid;
          grid-column-gap: 7px;
          grid-row-gap: 7px;
          grid-template-columns: 1fr 50px 50px;
          align-items: center;
          justify-items: center;
        }

        .url span {
          overflow-x: hidden;
        }

        .form {
          display: grid;
          align-items: center;
        }

        .field {
          margin-bottom: 15px;
        }

        .col-header {
          font-weight: 600;
          text-transform: capitalize;
          font-size: 0.8em;
        }

        .field-content {
          font-family: "Roboto Mono", Courier, monospace;
        }
        .field-content i {
          color: #8c8c8c;
        }
        h3.endpoint-slug {
          font-family: "Roboto Mono", Courier, monospace;
          font-size: 1.2em;
        }

        h3 i {
          color: #666;
        }

        .ant-input-group-addon {
          font-size: 1em;
          font-family: var(--default-ff);
        }

        .form i {
          cursor: pointer;
        }
      `}</style>
    </>
  );
}

let addTrailingSlash = (str: string) => {
  return str.replace(/\/$/, "") + "/";
};

enum Actions {
  SlugChanged,
  SlugEditToggle,
  ServerSelected,
  MethodSelected,
  PathChanged,
  AddQueryClicked,
  DelQueryClicked,
  QueryNameChanged,
  QueryRequiredToggled,
  RolesChanged,
  TestModalOpen,
  TestModalClosed,
}

type ActionObject =
  | {
      type: Actions.AddQueryClicked;
    }
  | {
      type: Actions.SlugChanged;
      value: string;
    }
  | {
      type: Actions.SlugEditToggle;
      editing: boolean;
    }
  | {
      type: Actions.ServerSelected;
      id: string;
    }
  | {
      type: Actions.MethodSelected;
      method: IRequestMethod;
    }
  | {
      type: Actions.PathChanged;
      value: string;
    }
  | {
      type: Actions.DelQueryClicked;
      idx: number;
    }
  | {
      type: Actions.QueryNameChanged;
      idx: number;
      name: string;
    }
  | {
      type: Actions.QueryRequiredToggled;
      idx: number;
      required: boolean;
    }
  | {
      type: Actions.RolesChanged;
      roles: string[];
    }
  | {
      type: Actions.TestModalOpen;
    }
  | {
      type: Actions.TestModalClosed;
    };

let reducer = (state: State, action: ActionObject): State => {
  switch (action.type) {
    case Actions.SlugEditToggle: {
      let { editing } = action;
      return {
        ...state,
        slugEditEnabled: editing,
      };
    }
    case Actions.SlugChanged: {
      let { value } = action;
      return {
        ...state,
        slug: value,
      };
    }
    case Actions.ServerSelected: {
      return {
        ...state,
      };
    }
    case Actions.MethodSelected: {
      let { method } = action; // todo validate
      return {
        ...state,
        method,
      };
    }
    case Actions.PathChanged: {
      let { value } = action;
      let vars = Variables.extractAll(value);
      return {
        ...state,
        path: value,
        pathVariables: vars,
      };
    }
    case Actions.AddQueryClicked: {
      return {
        ...state,
        queryParams: state.queryParams.concat(emptyQuery),
      };
    }
    case Actions.DelQueryClicked: {
      let { idx } = action;
      let { queryParams: queries } = state;
      let updated = update(queries, { $splice: [[idx, 1]] });
      // handle last query deleted
      if (updated.length === 0) {
        updated = [emptyQuery];
      }
      return {
        ...state,
        queryParams: updated,
      };
    }
    case Actions.QueryNameChanged: {
      let { idx, name } = action;
      let { queryParams } = state;
      let updated = update(queryParams, { [idx]: { name: { $set: name } } });
      return {
        ...state,
        queryParams: updated,
      };
    }
    case Actions.QueryRequiredToggled: {
      let { idx, required } = action;
      let { queryParams: queries } = state;
      let updated = update(queries, {
        [idx]: { required: { $set: required } },
      });
      return {
        ...state,
        queryParams: updated,
      };
    }
    case Actions.RolesChanged: {
      let { roles } = action;

      return {
        ...state,
        roles,
      };
    }
    case Actions.TestModalOpen: {
      return {
        ...state,
        showTestModal: true
      }
    }
    case Actions.TestModalClosed: {
      return {
        ...state,
        showTestModal: false
      }
    }
  }
};

let emptyQuery = {
  name: "",
  required: false,
};

import React, { useEffect } from "react";
import { Skeleton } from "antd";
import { WhiteBox } from "../components/MainLayout";
import BarebonesLayout from "../components/BarebonesLayout";
import { getLogoutUrl } from "../api";

export default function LogoutRedirect() {
  useEffect(() => {
    (window as any).location = getLogoutUrl();
  });

  return (
    <BarebonesLayout>
      <h1 className="header">Logging out...</h1>
      <WhiteBox>
        <Skeleton active />
      </WhiteBox>
    </BarebonesLayout>
  );
}

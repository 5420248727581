import React from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";

import AllResources from "./pages/AllResources";
import AllQueries from "./pages/AllQueries";
import AddQuery from "./pages/AddQuery";
import ShowQuery from "./pages/ShowQuery";
import ErrorBoundary from "./components/ErrorBoundary";
import LoginInit from "./pages/LoginInit";
import LoginCallback from "./pages/LoginCallback";
import ShowResource from "./pages/ShowResource";
import AddResource from "./pages/AddResource";
import LoadingPage from "./pages/LoadingPage";
import ErrorPage from "./pages/ErrorPage";
import LoggedOut from "./pages/LoggedOut";
import LogoutRedirect from "./pages/LogoutRedirect";
import Signup from "./pages/Signup";
import useAnalyticsTracking from "./util/useAnalyticsTracking";

const App: React.FC = () => {
  useAnalyticsTracking();
  let location = useLocation();

  return (
    // !hack: https://stackoverflow.com/a/61415043/862877
    <ErrorBoundary key={location.pathname}>
      <React.Suspense fallback={<LoadingPage />}>
        <Switch>
          <Route path="/login/callback/:provider">
            <LoginCallback />
          </Route>
          <Route path="/login">
            <LoginInit />
          </Route>
          <Route path="/logout/done">
            <LoggedOut />
          </Route>
          <Route path="/logout">
            <LogoutRedirect />
          </Route>
          <Route path="/signup">
            <Signup />
          </Route>
          <Route path="/resources/add/:kind">
            <AddResource />
          </Route>
          <Route path="/resources/:permaslug">
            <ShowResource />
          </Route>
          <Route path="/resources">
            <AllResources />
          </Route>
          <Route path="/queries/add">
            <AddQuery />
          </Route>
          <Route path="/queries/:queryPermaslug/resources/:resourcePermaslug">
            <ShowQuery />
          </Route>
          <Route path="/queries">
            <AllQueries />
          </Route>

          <Route exact path="/">
            <Redirect to="/queries" />
          </Route>
          <Route>
            <ErrorPage msg="That page does not exist" />
          </Route>
        </Switch>
      </React.Suspense>
    </ErrorBoundary>
  );
  // return (
  //   <EditHttpQuery
  //     servers={[
  //       {
  //         id: "1",
  //         name: "Decode Admin API",
  //       },
  //       {
  //         id: "2",
  //         name: "Airtable API",
  //       },
  //     ]}
  //   />
  // );
  // return (
  //   <ErrorBoundary>
  //     <LoginStatus>
  //       <Switch>
  //         <Route exact path="/demo-video">
  //           <RedirectToVideoDemo />
  //         </Route>
  //         <PrivateRoute exact path="/walkthrough">
  //           <WalkthroughApp />
  //         </PrivateRoute>
  //         <PrivateRoute path="/queries">
  //           <AllQueries />
  //         </PrivateRoute>
  //         <Route exact path="/logout">
  //           <Logout />
  //         </Route>
  //         <Route exact path="/">
  //           <GetStarted />
  //         </Route>
  //         <Route path="/signin">
  //           <SignIn />
  //         </Route>
  //         <Route path="/signup">
  //           <SignUp />
  //         </Route>
  //         <Route>
  //           <NotFound />
  //         </Route>
  //       </Switch>
  //     </LoginStatus>
  //     <Route component={ScrollToTop} />
  //   </ErrorBoundary>
  // );
};

export default App;

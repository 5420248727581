import { useState, useEffect } from "react";

interface Result {
  matches: {
    propName: string;
    matchRange: [number, number];
  }[];
  indexes: number[];
}

let getInitialState = () => ({ indexes: [], matches: [] });

export default function useFullObjectSearch(
  query: string,
  // o: { [k: string]: unknown }[]
  o: {}[]
) {
  const [results, setResults] = useState<Result>(getInitialState());

  let recomputeResults = () => {
    let res: Result = getInitialState();
    o.forEach((el, idx) => {
      for (let prop in el) {
        let val: unknown = (el as any)[prop];
        if (typeof val === "string") {
          // let regexp = RegExp(`(${escapeRegExp(query)})`, "i");
          let regexp = RegExp(escapeRegExp(query), "i");
          let m = regexp.exec(val);
          if (m) {
            let startIdx = m.index;
            let endIdx = m.index + m[0].length;
            res.indexes.push(idx);
            res.matches.push({
              propName: prop,
              matchRange: [startIdx, endIdx],
            });
            break;
          }
        }
      }
    });
    setResults(res);
  };

  useEffect(() => {
    if (query.length > 0) {
      recomputeResults();
    } else {
      setResults(getInitialState());
    }
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  return results;
}

function escapeRegExp(s: string) {
  return s.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

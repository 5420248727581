import React from "react";

import MainLayout from "../components/MainLayout";
import { useHistory } from "react-router-dom";
import ResourceList from "../components/ResourceList";
import { Button, Menu, Dropdown, message } from "antd";
import { PlusOutlined, DownOutlined } from "@ant-design/icons";
import { useResources } from "../api/hooks";
import { IResourceKind, ResourceKind } from "../api/types";
import { deleteDbResource, deleteHttpResource } from "../api";

export default function AllResources() {
  let { resources, mutate } = useResources();
  let history = useHistory();

  let handleDelete = async (id: string, kind: IResourceKind) => {
    if (!resources) {
      throw new Error(`Attempted delete before data was loaded`);
    }
    try {
      if (kind === ResourceKind.Values.db) {
        await deleteDbResource(id);
        let { db } = resources;
        let idx = db.findIndex((r) => r.id === id);
        mutate({
          ...resources,
          db: [...db.slice(0, idx), ...db.slice(idx + 1)],
        });
        message.success("Deleted");
      } else {
        await deleteHttpResource(id);
        let { http } = resources;
        let idx = http.findIndex((r) => r.id === id);
        mutate({
          ...resources,
          http: [...http.slice(0, idx), ...http.slice(idx + 1)],
        });
        message.success("Deleted");
      }
    } catch (e) {
      console.error(e);
      message.error("There was an error :( Try one more time?");
    }
  };

  let { http, db } = resources;
  let resourceList = [...http, ...db];
  const AddResourceMenu = (
    <Menu onClick={(e) => history.push(`/resources/add/${e.key}`)}>
      <Menu.Item key="db">
        <PlusOutlined /> Database
      </Menu.Item>
      <Menu.Item key="http">
        <PlusOutlined /> HTTP Resource
      </Menu.Item>
    </Menu>
  );

  return (
    <MainLayout>
      <div className="header">
        <h1>Resources</h1>

        <Dropdown overlay={AddResourceMenu}>
          <Button type="primary">
            <DownOutlined />
            Add resource
          </Button>
        </Dropdown>
      </div>
      <ResourceList
        resources={resourceList}
        onSelect={({ permaslug }) => history.push(`/resources/${permaslug}`)}
        onDelete={handleDelete}
      />
      <style jsx>{`
        .header {
          display: grid;
          grid-template-columns: 1fr auto;
          margin-bottom: 10px;
        }
      `}</style>
    </MainLayout>
  );
}

import React, { ReactElement, useState, useEffect, useMemo } from "react";
import {
  Select,
  Input,
  Form,
  Button,
  Space,
} from "antd";
import { FormInstance } from "antd/lib/form";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
import CodeMirrorJsonInput from "./CodeMirrorJsonInput";
import { TestHttpQuery as TestHttpQueryType, HttpResource } from "../api/types";
import * as Variables from "../util/variables";
import * as api from "../api";
import TestResultView from "./TestResultView";

interface Props {
  httpQuery: TestHttpQueryType;
  resource: HttpResource;
  onClose(): void;
}

interface QueryParameter {
  name: string;
  required: boolean;
}

export default function TestHttpQuery({
  httpQuery,
  resource,
  onClose,
}: Props): ReactElement {
  let doAllowBody = useMemo<boolean>(
    () => ["POST", "PUT", "PATCH"].includes(httpQuery.method),
    [httpQuery.method]
  );

  let [httpBody, setHttpBody] = useState<string>("{}");
  let [isHttpBodyValid, setHttpBodyValid] = useState<boolean>(true);
  let [testResult, setTestResult] = useState<api.TestResult>();
  let [isTestInProgress, setTestInProgress] = useState<boolean>(false);
  let [error, setError] = useState<string | undefined>();

  let [form] = Form.useForm();

  useEffect(() => {
    if (!doAllowBody) return;

    try {
      // if this doesn't throw, JSON is valid
      JSON.parse(httpBody);
      setHttpBodyValid(true);
    } catch {
      setHttpBodyValid(false);
    }
  }, [httpBody, doAllowBody]);

  const queryParams = httpQuery.queryParams.map(
    ({ name, required }: QueryParameter) => ({
      key: name,
      required: required,
      value: "",
    })
  );

  const urlParameters = Variables.extractCaller(
    httpQuery.path
  ).map((param) => ({ key: param, value: "" }));

  const formInitialValues = {
    urlParameters: urlParameters,
    queryParameters: queryParams,
    headers: [{ key: "", value: "" }],
    cookies: [{ key: "", value: "" }],
  };

  async function runHttpTest(values: FormValues): Promise<void> {
    const urlParameters = convertToMap(values.urlParameters);
    const queryParameters = convertToMap(values.queryParameters);
    const headers = convertToMap(values.headers);
    const cookies = convertToMap(values.cookies);
    const body = doAllowBody ? extractBody(httpBody) : undefined;

    setError(undefined);
    setTestResult(undefined);
    setTestInProgress(true);

    let [result, error] = await runTest({
      httpResourceId: resource.id,
      definition: httpQuery,
      parameters: {
        headers,
        cookies,
        body: body,
        urlParameters,
        queryParameters,
      },
    });

    if (error) {
      setError(error);
    } else {
      setTestResult(result ?? undefined);
    }

    setTestInProgress(false);
  }

  return (
    <div className="test-http-query-form">
      <div className="field">
        <h3 className="field-label">Action & Path</h3>
        <Input.Group compact>
          <Select
            disabled
            value={httpQuery.method}
            style={{ width: "30%" }}
          ></Select>
          <Input
            disabled
            style={{ width: "70%" }}
            value={mergeUrl(resource.definition.url, httpQuery.path)}
          />
        </Input.Group>
      </div>

      <Form
        form={form}
        // makes sure the form is reset after modal is closed and open again
        preserve={false}
        layout="vertical"
        initialValues={formInitialValues}
        onFinish={(values: FormValues) => runHttpTest(values)}
        autoComplete="off"
        requiredMark={true}
      >
        <Space direction="vertical" size="large">
          <InputList
            name="urlParameters"
            label="URL parameters"
            allowFieldAddRemove={false}
            form={form}
            required
            disableKeyInputs
          />

          <InputList
            name="queryParameters"
            label="Query parameters"
            allowFieldAddRemove={false}
            form={form}
            disableKeyInputs
          />

          <InputList name="headers" label="Headers" form={form} />

          <InputList name="cookies" label="Cookies" form={form} />

          {doAllowBody && (
            <div>
              <div className="ant-form-item-label">Body</div>

              <CodeMirrorJsonInput
                onBeforeChange={(_editor, _data, value) => {
                  setHttpBody(value);
                  // TODO - wrap the component to be usable within antd by default
                  // form.setFieldsValue({body: value});
                }}
                // value={form.getFieldValue('body')}
                value={httpBody}
              />

              {!isHttpBodyValid ? (
                <span className="error">Please ensure JSON is valid!</span>
              ) : null}
            </div>
          )}

          <TestResultView
            testResult={testResult}
            testInProgress={isTestInProgress}
            error={error}
          />
        </Space>

        <Form.Item className="modal-buttons">
          <Button type="ghost" onClick={onClose} className="left-button">
            Close
          </Button>

          <Button type="primary" htmlType="submit">
            Run test
          </Button>
        </Form.Item>
      </Form>

      <style jsx>
        {`
          h3.field-label {
            font-weight: 600;
            font-size: 1em;
          }

          // make the input groups lie on top of each other without any whitespace
          :global(.test-http-query-form .ant-row.ant-form-item) {
            margin-bottom: 0 !important;
          }

          // remove extra padding from labels and make the fonts thicker
          :global(.test-http-query-form .ant-form-item-label) {
            padding: 0;
            font-weight: 600;
            margin-bottom: 7px;
          }

          :global(.test-http-query-form .modal-buttons) {
            margin-top: 3rem;
          }

          :global(.test-http-query-form .ant-input.ant-input-disabled),
          :global(.test-http-query-form .ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector) {
            color: rgba(0, 0, 0, 0.85);
          }

          // ensure the cancel button is left and the other buttons are to the right
          :global(.test-http-query-form
              .modal-buttons
              .ant-form-item-control-input-content) {
            display: flex;
          }

          :global(.test-http-query-form .left-button) {
            margin-right: auto;
          }

          .error {
            color: #f5222d;
            padding: 0.2rem 0;
          }
        `}
      </style>
    </div>
  );
}

interface InputListProps {
  name: string;
  label: string;
  allowFieldAddRemove?: boolean;
  disableKeyInputs?: boolean;
  required?: boolean;
  form: FormInstance<any>;
}

function InputList({
  name,
  label,
  allowFieldAddRemove = true,
  required = false,
  disableKeyInputs = false,
  form,
}: InputListProps): ReactElement {
  let formValues = form.getFieldValue(name);

  return (
    <>
      <Form.List key="field-list" name={name}>
        {(fields, { add, remove }) => {
          return (
            <div>
              {fields.map((field, index) => {
                let isRequired =
                  formValues?.[field.fieldKey]?.required || required;

                return (
                  <Form.Item
                    key={field.key}
                    label={index === 0 ? label : undefined}
                    name={[field.name, "key"]}
                  >
                    <Input.Group compact className="full-width-items">
                      <Form.Item
                        className="key-item expand-item"
                        {...field}
                        key={`${field.fieldKey}.key`}
                        name={[field.name, "key"]}
                        rules={[
                          {
                            required: isRequired,
                            message: isRequired ? "Missing key" : undefined,
                          },
                        ]}
                      >
                        <Input disabled={disableKeyInputs} placeholder="key" />
                      </Form.Item>
                      <Form.Item
                        className="value-item expand-item"
                        {...field}
                        key={`${field.fieldKey}.value`}
                        name={[field.name, "value"]}
                        rules={[
                          {
                            required: isRequired,
                            message: isRequired
                              ? "Missing parameter"
                              : undefined,
                          },
                        ]}
                      >
                        <Input placeholder="value" />
                      </Form.Item>

                      {allowFieldAddRemove ? (
                        <Form.Item>
                          <div className="input-delete-icon">
                            <CloseOutlined
                              className="icon-color"
                              onClick={() => {
                                remove(field.name);
                              }}
                            />
                          </div>
                        </Form.Item>
                      ) : null}
                    </Input.Group>
                  </Form.Item>
                );
              })}

              {allowFieldAddRemove ? (
                <Form.Item>
                  <Button
                    className="text-align-left"
                    type="dashed"
                    onClick={() => {
                      add();
                    }}
                    block
                  >
                    <PlusOutlined /> Add New
                  </Button>
                </Form.Item>
              ) : null}
            </div>
          );
        }}
      </Form.List>
      <style jsx key="field-styles">
        {`
          :global(.text-align-left) {
            text-align: left;
          }

          :global(.icon-color) {
            color: rgba(0, 0, 0, 0.65);
          }

          .input-delete-icon {
            border: 1px solid #d9d9d9;
            min-height: 32px;
            line-height: 2;
            padding: 0 0.4rem;

            // fix borders
            border-radius: 0 2px 2px 0;

            // vertically center icon
            display: flex;
            align-items: center;
          }

          // expand the input group to take full width
          :global(.ant-input-group.ant-input-group-compact.full-width-items) {
            display: flex;
          }

          :global(.expand-item) {
            flex: 1;
          }

          :global(.key-item) {
            flex-basis: 170px;
            flex-grow: 0;
          }

          :global(.ant-input-group.full-width-items .ant-form-item input) {
            border-radius: 0 0 0 0;
          }

          :global(.ant-input-group.full-width-items
              .ant-form-item:first-child
              input) {
            border-radius: 2px 0 0 2px;
          }

          :global(.ant-input-group.full-width-items
              .ant-form-item:last-child
              input) {
            border-radius: 0 2px 2px 0;
          }
        `}
      </style>
    </>
  );
}

type RunTestResult = [api.TestResult, null] | [null, string];
export let runTest = async (
  testData: api.TestHttpQueryInput
): Promise<RunTestResult> => {
  try {
    let query = `${testData.definition.method} ${testData.definition.path}`;

    let r = await api.testHttpQuery(testData);

    let result;
    if (!r.ok) {
      const formattedError = r.error.upstreamError
      ? `${r.error.summary}\n${r.error.upstreamError}`
      : r.error.summary
      result = {
        ok: false,
        log: formattedError,
        query,
      };
    } else {
      let output = r.result;
      if (isSystemMessage(output)) {
        result = {
          ok: true,
          log: JSON.stringify(output.__system, null, 2),
          query,
        };
      } else {
        result = {
          ok: true,
          log: JSON.stringify(output, null, 2),
          query,
        };
      }
    }
    return [result, null];
  } catch (e) {
    console.error(`Caught exception: ${e}`);
    // captureException(e);
    return [null, "Something went wrong while running your test :( Try again?"];
  }
};

interface SystemMessage {
  __system: string;
}

function isSystemMessage(res: unknown): res is SystemMessage {
  return typeof res === "object" && res?.hasOwnProperty("__system")
    ? true
    : false;
}

interface Fields {
  key: string;
  value: string;
}

interface FormValues {
  urlParameters: Fields[];
  queryParameters: Fields[];
  headers: Fields[];
  cookies: Fields[];
  body?: string;
}

function convertToMap(fieldArray: Fields[]): api.HttpParam {
  return fieldArray
    .filter(({ value }) => value !== "")
    .reduce((map, { key, value }) => {
      map[key] = value;
      return map;
    }, {} as api.HttpParam);
}

function extractBody(stringifiedHttpBody: string): any {
  try {
    return JSON.parse(stringifiedHttpBody);
  } catch {
    // default to empty object, skip the validation - JSON is validated in the form logic
    return {};
  }
}

function mergeUrl(baseUrl: string, urlPath: string) {
  return baseUrl.endsWith('/')
    ? baseUrl + urlPath
    : `${baseUrl}/${urlPath}`;
}
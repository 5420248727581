import React, { useState, useRef, useEffect } from "react";
import { Input } from "antd";
import { InputProps } from "antd/lib/input";

export default function SecretInput(props: InputProps) {
  let [hovered, setHovered] = useState(false);
  let [focused, setFocused] = useState(false);
  let [hidden, setHidden] = useState(true);

  useEffect(() => {
    if (hovered) {
      setHidden(false);
      return;
    }

    if (focused) {
      setHidden(false);
      return;
    }

    setHidden(true);
  }, [hovered, focused]);

  return (
    <Input
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      {...props}
      autoComplete="off"
      type={hidden ? "password" : undefined}
    />
  );
}

import React from "react";
import MainLayout, { WhiteBox } from "../components/MainLayout";
import { message } from "antd";
import { useHistory, useParams } from "react-router-dom";
import EditHttpResource from "../components/EditHttpResource";
import {
  DbResource as IDbResource,
  HttpResource as IHttpResource,
  ResourceKind,
  HttpResourceModifiableParams,
} from "../api/types";
import * as api from "../api";
import EditDbResource from "../components/EditDbResource";
import { useResource } from "../api/hooks";

export default function ShowResource() {
  let history = useHistory();
  let { permaslug } = useParams<{ permaslug: string }>();
  if (!permaslug) {
    throw new Error(
      "Rendered ShowResource without a resource in :permaslug param"
    );
  }
  let { resource, mutate } = useResource(permaslug);

  let handleHttpSave = async (
    params: HttpResourceModifiableParams,
    id: string
  ) => {
    try {
      mutate({ ...resource, ...params });
      await api.updateHttpResource(id, params);
      history.push("/resources");
      message.success("Saved");
    } catch (error) {
      throw error;
    }
  };

  return (
    <MainLayout
      navHeader={{
        onBack: () => history.push("/resources"),
        title: "Back to all resources",
      }}
    >
      <div className="header">
        <h1>Resource</h1>
      </div>
      <WhiteBox>
        {isDbResource(resource) ? (
          <EditDbResource db={resource} mutate={mutate} />
        ) : (
          <EditHttpResource
            onCancel={() => history.push("/resources")}
            onSubmit={handleHttpSave}
            resource={resource}
          />
        )}
      </WhiteBox>
      <style jsx>{`
        .header {
          display: grid;
          grid-template-columns: 1fr auto;
          margin-bottom: 10px;
        }
      `}</style>
    </MainLayout>
  );
}

type Resource = IHttpResource | IDbResource;
function isDbResource(q: Resource): q is IDbResource {
  return q.kind === ResourceKind.Values.db;
}

import React, { ReactElement, useReducer, useEffect, useRef } from "react";
import { Modal, message } from "antd";

import * as api from "../api";
import EditDbQuery, { DbQueryModifiableParams } from "./EditDbQuery";
// import { UserIdContext } from "./PrivateRoute";
// import { captureException } from "./SentryClient";
import * as Variables from "../util/variables";
import { DbQuery } from "../api/types";
import EditTestFields from "./EditTestFields";
import { useOrg } from "../api/hooks";

interface Props {
  onCancel(): void;
  onSave(params: DbQueryModifiableParams, id?: string): void;
  query?: DbQuery;
  resourceId: string;
}

const EditDbQueryContainer = (props: Props): ReactElement => {
  let { query, resourceId, onCancel, onSave } = props;
  let { org } = useOrg();

  let allRoles = org ? org.roles : [];

  let initialState = getInitialState(query);
  let [state, dispatch] = useReducer(reducer, initialState);
  // Used to ensure save only runs once on transition to Steps.Saving
  let saveStarted = useRef(false);

  let {
    step,
    slug,
    statement,
    statementError,
    error,
    testResult,
    testFields,
    variables,
    roles,
  } = state;

  useEffect(() => {
    if (saveStarted.current) return;

    const runEffect = async () => {
      saveStarted.current = true;

      let id = query && query.id;
      // let payload = { databaseId: resourceId, value: queryValue, testFields };
      try {
        onSave({ slug, statement, roles }, id);
        // if (id) {

        //   await api.updateDbQuery(id, { slug, statement, resourceId})
        //   // await api.saveQuery(userId, id, payload);
        //   // analytics.track("Query updated", { databaseId: resourceId, id });
        // } else {
        //   // id = await api.createQuery(userId, payload);
        //   // analytics.track("Query created", { databaseId: resourceId, id });
        // }
      } catch (e) {
        // captureException(e);
        console.error(e);
        saveStarted.current = false;
        dispatch({
          type: Actions.SaveFailed,
          error: "Something went wrong. It might be on our end :( Try again?",
        });
      }
      // history.push(`/queries/${id}`); message.success(query ? "Query updated" : "Query Created");
    };

    if (step === Steps.Saving) {
      runEffect();
    }
  }, [step]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const runEffect = async () => {
      let [res, err] = await runTest({
        databaseId: resourceId,
        value: statement,
        id: query && query.id,
        fields: testFields,
      });
      if (err) {
        dispatch({
          type: Actions.TestFailedToComplete,
          error: err,
        });
        return;
      }

      dispatch({
        type: Actions.TestCompleted,
        result: res as api.TestResult,
      });

      if (step === Steps.TestingBeforeSave) {
        if (res?.ok) {
          dispatch({ type: Actions.SaveInitiated });
        } else {
          dispatch({
            type: Actions.SaveFailed,
            error: "Unable to save, query test failed.",
          });
        }
      }
    };

    if (step === Steps.TestingBeforeSave || step === Steps.Testing) {
      runEffect();
    }
  }, [step]); // eslint-disable-line react-hooks/exhaustive-deps

  let handleTestInitiate = async (saveAfter = false) => {
    let currentFields = Variables.extractCaller(statement);
    let missingTestField = currentFields.find((name) => {
      let field = testFields[name];
      if (!field) {
        return true;
      } else {
        return field.length < 1;
      }
    });
    if (missingTestField) {
      dispatch({ type: Actions.EditTestFieldsInitiated });
      return;
    }

    if (saveAfter) {
      dispatch({ type: Actions.TestBeforeSaveInitiated });
    } else {
      dispatch({ type: Actions.TestInitiated });
    }
  };

  let handleSave = async () => {
    // skip running tests if there's variables
    // this is a temp workaround until we improve the DB query testing logic
    let runTests = variables.length === 0;

    // if (!resourceId) {
    //   dispatch({
    //     type: Actions.SaveFailed,
    //     error: "Please select a database.",
    //   });
    //   return;
    // }
    if (!runTests) {
      dispatch({
        type: Actions.SaveInitiated,
      });
      return;
    }

    if (testResult) {
      if (testResult.query !== statement) {
        handleTestInitiate(true);
      } else {
        if (testResult.ok) {
          dispatch({
            type: Actions.SaveInitiated,
          });
        } else {
          dispatch({
            type: Actions.SaveFailed,
            error: "Query appears to have an error.",
          });
        }
      }
    } else {
      handleTestInitiate(true);
    }
  };

  let handleTestFieldsSave = (
    fields: { [k: string]: string },
    testAfter = false
  ) => {
    dispatch({ type: Actions.EditTestFieldsSuccess, fields, testAfter });
  };

  let handleTestEdit = () => {
    dispatch({ type: Actions.EditTestFieldsInitiated });
  };

  let saveInProgress = [Steps.TestingBeforeSave, Steps.Saving].includes(step);
  let testInProgress = [Steps.TestingBeforeSave, Steps.Testing].includes(step);
  let callerVariables = variables.filter((v) => v.provider === "caller");
  // let urlPrefix = `https://${organization?.slug}.usedecode.com/e/`;
  let urlPrefix = `https://api.usedecode.com/e/`;

  return (
    <>
      <Modal
        title="Edit test variables"
        visible={step === Steps.EditingTestFields}
        onCancel={() => dispatch({ type: Actions.EditTestFieldsCanceled })}
        footer={null}
      >
        <EditTestFields
          fieldNames={callerVariables.map(({ name }) => name)}
          fieldValues={testFields}
          onSaveAndTest={(fields: { [k: string]: string }) => {
            handleTestFieldsSave(fields, true);
          }}
        />
      </Modal>
      <EditDbQuery
        allRoles={allRoles}
        selectedRoles={roles}
        statement={statement}
        statementError={statementError}
        slug={slug}
        saveInProgress={saveInProgress}
        testInProgress={testInProgress}
        testResult={testResult}
        error={error}
        urlPrefix={urlPrefix}
        onQueryChange={(value: string) =>
          dispatch({ type: Actions.QueryValueChanged, value })
        }
        onSlugChange={(value: string) =>
          dispatch({ type: Actions.SlugValueChanged, value })
        }
        onRolesChange={(value: string[]) =>
          dispatch({ type: Actions.RolesChanged, value })
        }
        onTestInitiate={handleTestInitiate}
        onTestEdit={callerVariables.length > 0 ? handleTestEdit : undefined}
        onCancel={onCancel}
        onSave={handleSave}
      />
    </>
  );
};

let getInitialState = (query: Props["query"]): State => {
  let variables: Variables.Variable[] = [];
  if (query) {
    variables = Variables.extractAll(query.statement);
  }
  return {
    slug: query?.slug ?? "",
    statement: query?.statement ?? "",
    statementError: undefined,
    roles: query?.roles ?? [],
    step: Steps.Idle,
    testFields: query?.testFields ?? {},
    variables: variables,
    id: query?.id,
    detailsOpen: false,
  };
};

enum Steps {
  Idle = "Idle",
  Testing = "Testing",
  TestingBeforeSave = "TestingBeforeSave",
  Saving = "Saving",
  EditingTestFields = "EditingTestFields",
}

interface State {
  step: Steps;
  slug: string;
  statement: string;
  statementError?: string;
  id?: string;
  testFields: { [k: string]: string };
  variables: Variables.Variable[];
  error?: string;
  testResult?: api.TestResult;
  detailsOpen: boolean;
  roles: string[];
}

enum Actions {
  TestInitiated,
  TestBeforeSaveInitiated,
  TestCompleted,
  TestFailedToComplete,
  EditTestFieldsInitiated,
  EditTestFieldsCanceled,
  EditTestFieldsSuccess,
  QueryValueChanged,
  SlugValueChanged,
  RolesChanged,
  SaveInitiated,
  SaveFailed,
  DetailsToggled,
}

type ActionObject =
  | {
      type:
        | Actions.TestInitiated
        | Actions.TestBeforeSaveInitiated
        | Actions.EditTestFieldsInitiated
        | Actions.EditTestFieldsCanceled
        | Actions.SaveInitiated;
    }
  | {
      type: Actions.TestCompleted;
      result: api.TestResult;
    }
  | {
      type: Actions.QueryValueChanged;
      value: string;
    }
  | {
      type: Actions.SlugValueChanged;
      value: string;
    }
  | {
      type: Actions.RolesChanged;
      value: string[];
    }
  | {
      type: Actions.SaveFailed;
      error: string;
    }
  | {
      type: Actions.TestFailedToComplete;
      error: string;
    }
  | {
      type: Actions.EditTestFieldsSuccess;
      fields: { [k: string]: string };
      testAfter: boolean;
    }
  | {
      type: Actions.DetailsToggled;
      open: boolean;
    };

let reducer = (state: State, action: ActionObject): State => {
  switch (action.type) {
    case Actions.TestInitiated: {
      return {
        ...state,
        error: undefined,
        step: Steps.Testing,
      };
    }
    case Actions.TestBeforeSaveInitiated: {
      return {
        ...state,
        error: undefined,
        step: Steps.TestingBeforeSave,
      };
    }
    case Actions.TestCompleted: {
      let { result } = action;
      let { step: stateName } = state;
      let nextStateName;
      if (stateName === Steps.Testing) {
        nextStateName = Steps.Idle;
      }
      return {
        ...state,
        step: nextStateName ?? stateName,
        testResult: result,
      };
    }
    case Actions.TestFailedToComplete: {
      let { error } = action;
      return {
        ...state,
        step: Steps.Idle,
        error,
      };
    }
    case Actions.QueryValueChanged: {
      let { value: statement } = action;

      // when the SQL query changes, the variables can change as well
      // so update those just in case
      let variables = Variables.extractAll(statement);

      // and in turn, when the variables change, the test fields might have changed as well,
      // remove the ones which are no longer present
      let variableNames = variables.map((variables) => variables.name);
      let newTestFields = Object.keys(state.testFields)
        .filter((key) => variableNames.includes(key))
        .reduce((acc: { [k: string]: string }, key: string) => {
          acc[key] = state.testFields[key];
          return acc;
        }, {});

      let statementError;
      let invalidSqlVar = Variables.getInvalidSqlVars(statement);
      if (invalidSqlVar) {
        statementError = `Don't put characters around variables (as you're doing with ${invalidSqlVar}). We'll interpolate variables when you send them in your request. If you want to use LIKE operators ('%'), just send the variable with them (eg. '%Paul%').`;
      }

      return {
        ...state,
        statement,
        statementError,
        variables: variables,
        testFields: newTestFields,
      };
    }
    case Actions.SlugValueChanged: {
      let { value } = action;
      return {
        ...state,
        slug: value,
      };
    }
    case Actions.RolesChanged: {
      let { value } = action;
      return {
        ...state,
        roles: value,
      };
    }
    case Actions.SaveInitiated: {
      return {
        ...state,
        step: Steps.Saving,
        error: undefined,
      };
    }
    case Actions.SaveFailed: {
      let { error } = action;
      return {
        ...state,
        step: Steps.Idle,
        error,
      };
    }
    case Actions.EditTestFieldsInitiated: {
      let variables = Variables.extractAll(state.statement);
      if (variables.length === 0) {
        message.info("No variables found in current query.");
        return {
          ...state,
          variables: [],
        };
      }
      return {
        ...state,
        step: Steps.EditingTestFields,
        variables,
      };
    }
    case Actions.EditTestFieldsCanceled: {
      return {
        ...state,
        step: Steps.Idle,
      };
    }
    case Actions.EditTestFieldsSuccess: {
      let { fields, testAfter } = action;
      return {
        ...state,
        testFields: fields,
        error: undefined,
        step: testAfter ? Steps.Testing : Steps.Idle,
      };
    }
    case Actions.DetailsToggled: {
      return {
        ...state,
        detailsOpen: action.open,
      };
    }
  }
};

type RunTestResult = [api.TestResult, null] | [null, string];
export let runTest = async ({
  databaseId,
  value,
  fields,
}: {
  databaseId: string;
  value: string;
  fields: { [k: string]: string };
  id?: string;
}): Promise<RunTestResult> => {
  try {
    let testFields = Object.keys(fields).reduce((memo, fieldName) => {
      return memo.concat({ name: fieldName, value: fields[fieldName] });
    }, [] as api.TestField[]);
    let r = await api.testDbQuery(value, databaseId, testFields);
    // analytics.track("Query tested", {
    //   databaseId,
    //   result: r.status,
    //   id
    // });
    let result;
    if (!r.ok) {
      result = {
        ok: false,
        log: r.error.summary,
        query: value,
      };
    } else {
      let output = r.result;
      if (isSystemMessage(output)) {
        result = {
          ok: true,
          log: JSON.stringify(output.__system, null, 2),
          query: value,
        };
      } else {
        result = {
          ok: true,
          log: JSON.stringify(output, null, 2),
          query: value,
        };
      }
    }
    return [result, null];
  } catch (e) {
    console.error(`Caught exception: ${e}`);
    // captureException(e);
    return [null, "Something went wrong while running your test :( Try again?"];
  }
};

interface SystemMessage {
  __system: string;
}

function isSystemMessage(res: unknown): res is SystemMessage {
  return typeof res === "object" && res?.hasOwnProperty("__system")
    ? true
    : false;
}

export default EditDbQueryContainer;

import React from "react";
import {
  Controlled as CodeMirror,
  IControlledCodeMirror,
} from "react-codemirror2";

// import this to support JSON mode
import "codemirror/mode/javascript/javascript";

export default function CodeMirrorJsonInput(props: IControlledCodeMirror) {
  let { options = {}, ...rest } = props;

  return (
    <CodeMirror
      {...rest}
      options={{
        ...defaultOptions,
        ...options
      }}
    />
  );
}

const defaultOptions = {
  theme: "default",
  height: "auto",
  mode: {
    name: "javascript",
    json: true,
    statementIndent: 2,
  },
  lineNumbers: false,
  lineWrapping: false,
  indentWithTabs: false,
  tabSize: 2,
  scrollbarStyle: "null",
  dragDrop: false,
};

import React, { useState } from "react";
import {
  Input,
  Form,
  Divider,
  Button,
  Select,
  InputNumber,
  Tooltip,
  message,
  notification,
} from "antd";
import { DbResource, DbResourceModifiableParams } from "../api/types";
import { EditOutlined, UndoOutlined } from "@ant-design/icons";
import SecretInput from "./SecretInput";
import * as api from "../api";
import { useHistory } from "react-router-dom";
import { normalizeErrorsForFormAntd } from "../util/form";

let { Option } = Select;

enum TestResult {
  None = "None",
  Success = "Success",
  Fail = "Fail",
}

interface Props {
  db?: DbResource;
  mutate: (data?: any, shouldRevalidate?: boolean) => void;
}

export default function EditDbResource({ db, mutate }: Props) {
  let [form] = Form.useForm();
  let resetPassword = () => form.resetFields([["connection", "password"]]);
  let [isLoading, setLoading] = useState<boolean>(false);
  let [testResult, setTestResult] = useState<TestResult>(TestResult.None);
  let history = useHistory();

  let isEdit = db?.id ? true : false;
  let header = isEdit ? "Edit database" : "New database";

  let handleFieldsChanged = () => {
    setTestResult(TestResult.None);

    // if they changed kind field, we'll set port for them
    let kindField = form.getFieldValue("dbKind");
    let portName = ["connection", "port"];
    if (!isEdit && kindField && !form.isFieldTouched(portName)) {
      let port = getPortForKind(kindField);
      form.setFields([{ name: portName, value: port }]);
    }
  };

  let handleSubmit = (v: { [n: string]: any }) => {
    handleDbSave(
      {
        connection: v.connection,
        name: v.name,
        dbKind: v.dbKind,
      },
      db?.id
    );
  };
  let handleTest = () => {
    let v = form.getFieldsValue();
    handleDbConnectionTest(
      {
        connection: v.connection,
        name: v.name,
        dbKind: v.dbKind,
      },
      db?.id
    );
  };

  let handleDbSave = async (
    params: DbResourceModifiableParams,
    id?: string
  ) => {
    try {
      setLoading(true);

      const testResult = await api.testDbResource(params, id);
      if (!testResult.ok) {
        openDbConnectionError();
        setTestResult(TestResult.Fail);

        setLoading(false);

        return;
      }

      if (id && db) {
        mutate({ ...db, ...params });
        await api.updateDbResource(id, params);
        history.push("/resources");
        message.success("Saved");
      } else {
        let result = await api.createDbResource(params);
        if (result.isOk()) {
          mutate();
          history.push("/resources");
          message.success("Saved");
        } else {
          if (result.error.error.validation_errors) {
            let validationErrors = normalizeErrorsForFormAntd(result.error);
            // the frontend calls this field "dbKind" and backend calls it "kind"
            // this is how we can remap the fields
            validationErrors = validationErrors.map((field) => {
              if (field.name === "kind") {
                return {
                  ...field,
                  name: "dbKind",
                };
              } else {
                return field;
              }
            });
            form.setFields(validationErrors);
          }
          message.error(result.error.error.summary);
        }
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  let handleDbConnectionTest = async (
    formValues: { [n: string]: any },
    id?: string
  ) => {
    try {
      setLoading(true);

      const result = await api.testDbResource(
        {
          name: "db_resource",
          connection: formValues.connection,
          dbKind: formValues.dbKind,
        },
        id
      );

      if (result.ok) {
        setTestResult(TestResult.Success);
      } else {
        openDbConnectionError();
        setTestResult(TestResult.Fail);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
      setTestResult(TestResult.Fail);
    }
  };

  let handleCancel = () => history.push("/queries");

  let renderTestButton = () => {
    switch (testResult) {
      case TestResult.None: {
        return (
          <Button onClick={handleTest} disabled={isLoading} loading={isLoading}>
            Test
          </Button>
        );
      }
      case TestResult.Success: {
        return (
          <Button disabled={true}>
            <span role="img" aria-label="Checked">
              ✅
            </span>{" "}
            Test success
          </Button>
        );
      }
      case TestResult.Fail: {
        return (
          <Button disabled={true}>
            <span role="img" aria-label="Crossed">
              ❌
            </span>{" "}
            Test failed
          </Button>
        );
      }
    }
  };

  return (
    <div className="container">
      <Form
        form={form}
        onFinish={handleSubmit}
        onFieldsChange={handleFieldsChanged}
        layout="vertical"
        initialValues={db}
      >
        <h3 className="section-label">{header}</h3>
        <Form.Item name="name" rules={[{ required: true }]}>
          <Input placeholder="e.g. Postgres - Production" />
        </Form.Item>
        <Form.Item name="dbKind" rules={[{ required: true }]}>
          <Select className="db-kind" placeholder="Select database type">
            <Option value="postgres">Postgres</Option>
            <Option value="mysql">MySQL</Option>
            <Option value="mssql">Microsoft SQL</Option>
          </Select>
        </Form.Item>
        <Divider />
        <h3 className="section-label">Connection</h3>
        <Form.Item
          label="Hostname"
          name={["connection", "host"]}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Database name"
          name={["connection", "dbname"]}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Port"
          name={["connection", "port"]}
          rules={[{ required: true, type: "number" }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label="Username"
          name={["connection", "user"]}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <PasswordField isEdit={isEdit} reset={resetPassword} />
        <div className="action-buttons">
          <Button className="cancel-action-button" onClick={handleCancel}>
            Cancel
          </Button>
          {renderTestButton()}
          <Button
            htmlType="submit"
            type="primary"
            disabled={isLoading || testResult === TestResult.Fail}
            loading={isLoading}
          >
            Save
          </Button>
        </div>
      </Form>
      <style jsx>{`
        h3.section-label {
          margin: 0px 0px 10px 0px;
        }
        .container :global(.db-kind) {
          max-width: 250px;
        }
        .container :global(.ant-row.ant-form-item) {
          max-width: 450px;
          margin-right: auto;
          margin-left: auto;
        }
        .action-buttons {
          display: flex;
        }
        .action-buttons :global(button:first-child) {
          margin-right: auto;
        }
        .action-buttons :global(button + button) {
          margin-left: 15px;
        }
      `}</style>
    </div>
  );
}

let openDbConnectionError = () =>
  notification.error({
    message: "Error saving database resource",
    description:
      "We had trouble connecting to your database. Please verify the connection settings are correct and try again.",
    duration: 7,
  });

let getPortForKind = (kind: string) => {
  switch (kind) {
    case "postgres":
      return 5432;
    case "mysql":
      return 3306;
    case "mssql":
      return 1433;
  }
};

let PasswordField = ({ isEdit, reset }: { isEdit: boolean; reset(): void }) => {
  let [editDisabled, setEditDisabled] = useState(isEdit);

  let cancelPasswordChange = () => {
    setEditDisabled(true);
    reset();
  };

  if (isEdit) {
    let addOn = editDisabled ? (
      <Tooltip title="Edit password">
        <EditOutlined onClick={() => setEditDisabled(false)} />
      </Tooltip>
    ) : (
      <Tooltip title="Cancel & reset value">
        <UndoOutlined onClick={cancelPasswordChange} />
      </Tooltip>
    );
    return (
      <Form.Item
        label="Password"
        name={["connection", "password"]}
        rules={[{ required: editDisabled ? false : true }]}
      >
        <SecretInput
          addonAfter={addOn}
          disabled={editDisabled}
          placeholder="•••••••••••"
        />
      </Form.Item>
    );
  } else {
    return (
      <Form.Item
        label="Password"
        name={["connection", "password"]}
        rules={[{ required: true }]}
      >
        <SecretInput placeholder="•••••••••••" />
      </Form.Item>
    );
  }
};

import React from "react";
import { Input, Checkbox } from "antd";

import { TestResult } from "../api";
import CalloutBubble from "./CalloutBubble";
import { Controlled as CodeMirror } from "react-codemirror2";
import EditQueryActionButtons, { BtnState } from "./EditQueryActionButtons";
import { DbQuery, Role } from "../api/types";
import TestResultView from "./TestResultView";

export type DbQueryModifiableParams = Pick<
  DbQuery,
  "slug" | "statement" | "roles"
>;

export type CalloutableComponents =
  | "db-select"
  | "edit-query"
  | "test-query"
  | "save-query";

interface Props {
  allRoles: Role[];
  selectedRoles: string[];
  statement: string;
  statementError?: string;
  slug: string;
  calloutComponent?: CalloutableComponents;
  testInProgress: boolean;
  saveInProgress: boolean;
  testResult?: TestResult;
  error?: string;
  urlPrefix: string;
  onTestInitiate(): void;
  onTestEdit?(): void;
  onQueryChange(val: string): void;
  onSlugChange(val: string): void;
  onRolesChange(vals: string[]): void;
  onCancel(): void;
  onSave(runTests?: boolean): void;
}

export default function EditDbQuery({
  allRoles,
  selectedRoles,
  statement,
  statementError,
  slug,
  calloutComponent,
  testInProgress,
  saveInProgress,
  testResult,
  urlPrefix,
  onTestInitiate,
  onTestEdit,
  onQueryChange,
  onSlugChange,
  onRolesChange,
  error,
  onCancel,
  onSave,
}: Props) {
  let roleOptions = allRoles.map(({ id, name }) => ({
    label: name,
    value: id,
    disabled: false,
  }));

  let codemirrorClasses = statementError
    ? "codemirror-container codemirror-container-has-error"
    : "codemirror-container";

  let actionButtons = () => {
    let cancelBtnState = saveInProgress ? BtnState.disabled : undefined;
    let testBtnState: BtnState | undefined = undefined;
    if (onTestEdit) {
      if (statement.length < 1 || saveInProgress || testInProgress) {
        testBtnState = BtnState.disabled;
      }
    } else {
      if (testInProgress) {
        testBtnState = BtnState.loading;
      } else if (statement.length < 1 || saveInProgress) {
        testBtnState = BtnState.disabled;
      }
    }
    let saveBtnState: BtnState | undefined = undefined;
    if (saveInProgress) {
      saveBtnState = BtnState.loading;
    } else if (testInProgress || statement.length < 1 || slug.length < 1) {
      saveBtnState = BtnState.disabled;
    }

    let btnStates = {
      cancel: cancelBtnState,
      test: testBtnState,
      save: saveBtnState,
    };

    return (
      <EditQueryActionButtons
        onCancelClick={onCancel}
        onTestClick={() => onTestInitiate()}
        onTestEditClick={onTestEdit}
        onSaveClick={onSave}
        states={btnStates}
      />
    );
  };

  return (
    <div className="edit-db-query">
      <h3 className="row-label">Endpoint</h3>
      <div className="endpoint-slug">
        <Input
          addonBefore={urlPrefix}
          value={slug}
          placeholder="listUsers"
          onChange={({ currentTarget: { value } }) => onSlugChange(value)}
        />
      </div>
      <h3 className="row-label">Query</h3>
      <CalloutBubble active={calloutComponent === "edit-query"}>
        {statementError ? (
          <div className="query-error">{statementError}</div>
        ) : null}
        <div className={codemirrorClasses}>
          <CodeMirror
            onBeforeChange={(_editor, _data, value) => {
              onQueryChange(value);
            }}
            className="sql-query-editor"
            value={statement}
            options={{
              mode: "simple-decode-vars",
              theme: "decode",
              dragDrop: false,
              placeholder: "select * from users limit 10000;",
            }}
          />
        </div>
      </CalloutBubble>
      <h3 className="row-label">Roles</h3>
      <div className="roles">
        {roleOptions.length > 0 ? (
          <Checkbox.Group
            options={roleOptions}
            defaultValue={selectedRoles}
            onChange={(roles) => onRolesChange(roles as string[])}
          />
        ) : (
          "No roles have been setup."
        )}
      </div>
      <TestResultView
        testResult={testResult}
        testInProgress={testInProgress}
        error={error}
      />
      {actionButtons()}

      <style jsx>{`
        .edit-db-query {
          display: grid;
        }
        .endpoint-slug {
          margin-bottom: 15px;
          max-width: 450px;
        }
        .field-label {
          font-weight: 600;
        }
        .codemirror-container {
          min-width: 0px;
          margin-bottom: 25px;
        }
        .codemirror-container-has-error :global(.CodeMirror) {
          border-color: #ff4d4f;
        }
        .query-error {
          color: #ff4d4f;
          margin-bottom: 0.5rem;
        }
        :global(.sql-query-editor .CodeMirror) {
          min-height: 150px;
        }
        :global(.sql-query-editor .CodeMirror-scroll) {
          min-height: 150px;
          max-height: 600px;
          overflow: visible;
        }
        :global(.test-save-btn-grp) {
          display: grid;
          grid-template-columns: 1fr auto;
        }
        .save-btn {
          grid-area: save;
        }
        .save-btn :global(button) {
          width: 100% !important;
        }
        .save-btn :global(span) {
          width: 100%;
        }

        .action-buttons {
          display: grid;
          grid-column-gap: 10px;
          grid-row-gap: 10px;
          grid-template-areas: "cancel spacer test save";
          grid-template-columns: auto 1fr auto auto;
          justify-content: right;
        }
        .action-buttons .cancel {
          grid-area: cancel;
        }
        .action-buttons .test {
          grid-area: cancel;
        }
        .roles {
          margin-bottom: 15px;
        }

        @media (min-width: 650px) {
          .action-buttons {
            grid-template-columns: 100px 1fr 100px 100px;
          }
          .action-buttons button {
            width: 100%;
          }
        }
      `}</style>
    </div>
  );
}

import React, { useState } from "react";
import MainLayout, { WhiteBox } from "../components/MainLayout";
import { Button, message } from "antd";
import EditDbQueryContainer from "../components/EditDbQueryContainer";
import EditHttpQuery, {
  HttpQueryModifiableParams,
} from "../components/EditHttpQuery";
import { useHistory, useParams } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";
import {
  DbQuery as IDbQuery,
  HttpQuery as IHttpQuery,
  ResourceKind,
  HttpResource,
} from "../api/types";
import HttpQuery from "../components/HttpQuery";
import DbQuery from "../components/DbQuery";
import * as api from "../api";
import { DbQueryModifiableParams } from "../components/EditDbQuery";
import { useQuery } from "../api/hooks";

function ShowDbQuery({
  isEditing,
  onCancel,
  onSave,
  query,
  resourceId,
}: {
  isEditing: boolean;
  onCancel: () => void;
  onSave: (params: DbQueryModifiableParams, id: string) => void;
  query: IDbQuery;
  resourceId: string;
}) {
  if (isEditing) {
    return (
      <EditDbQueryContainer
        resourceId={resourceId}
        onSave={onSave}
        query={query}
        onCancel={onCancel}
      />
    );
  } else {
    return <DbQuery query={query} />;
  }
}

function ShowHttpQuery({
  isEditing,
  onCancel,
  onSave,
  query,
  resource,
}: {
  isEditing: boolean;
  onCancel: () => void;
  onSave: (params: HttpQueryModifiableParams, id: string) => void;
  query: IHttpQuery;
  resource: HttpResource;
}) {
  if (isEditing) {
    return (
      <EditHttpQuery
        resource={resource}
        onSave={onSave}
        query={query}
        onCancel={onCancel}
      />
    );
  } else {
    return <HttpQuery query={query} />;
  }
}

export default function ShowQuery() {
  let history = useHistory();
  let [isEditing, setIsEditing] = useState(true);
  let { resourcePermaslug, queryPermaslug } = useParams<{ resourcePermaslug: string, queryPermaslug: string }>();
  let { query, mutate } = useQuery(queryPermaslug, resourcePermaslug);

  let handleHttpSave = async (
    params: HttpQueryModifiableParams,
    id: string
  ) => {
    await api.updateHttpQuery(id, params);
    mutate({ ...query, ...params });
    message.success("Saved");
    setIsEditing(false);
  };

  let handleDbSave = async (params: DbQueryModifiableParams, id: string) => {
    await api.updateDbQuery(id, params);
    mutate({ ...query, ...params });
    message.success("Saved");
    setIsEditing(false);
  };

  return (
    <MainLayout
      navHeader={{
        onBack: () => history.push("/queries"),
        title: "Back to all queries",
      }}
    >
      <div className="header">
        <h1>Query</h1>
        {!isEditing && (
          <Button onClick={() => setIsEditing(true)} type="primary">
            <EditOutlined />
            Edit query
          </Button>
        )}
      </div>
      <WhiteBox>
        {isDbQuery(query) ? (
          <ShowDbQuery
            resourceId={query.resource.id}
            query={query}
            isEditing={isEditing}
            onSave={handleDbSave}
            onCancel={() => setIsEditing(false)}
          />
        ) : (
          <ShowHttpQuery
            resource={query.resource}
            query={query}
            isEditing={isEditing}
            onSave={handleHttpSave}
            onCancel={() => setIsEditing(false)}
          />
        )}
      </WhiteBox>
      <style jsx>{`
        .header {
          display: grid;
          grid-template-columns: 1fr auto;
          margin-bottom: 10px;
        }
      `}</style>
    </MainLayout>
  );
}

type Query = IHttpQuery | IDbQuery;
function isDbQuery(q: Query): q is IDbQuery {
  return q.resource.kind === ResourceKind.Values.db;
}

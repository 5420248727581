import useSWR, { ConfigInterface } from "swr";
import moment from "moment";
import {
  responses,
  ListOrgResources,
  HttpQuery,
  DbQuery,
  HttpResource,
  DbResource,
  ListOrgQueries,
  Organization,
  GetUserOrg,
  GetUser,
  User,
  Env,
  ListOrgEnvs,
} from "./types";
import { FetchError, ForbiddenError, NotAuthorizedError } from ".";

export let useResource = (permaslug: string) => {
  let { resources, mutate: mutateAll } = useResources();

  let mutate = (data: any, shouldRevalidate?: boolean) => {
    if (!resources) {
      // Only will get here if mutate is called prematurely
      throw new Error(
        `Attempted to get resource but none loaded. This will happen if you attempt to mutate before data has been fully loaded.`
      );
    }

    let idx = resources.db.findIndex((r) => r.permaslug === permaslug);
    if (idx !== -1) {
      let db = Object.assign([], resources.db, { [idx]: data });
      mutateAll({ db, http: resources.http }, shouldRevalidate);
      return;
    }
    idx = resources.http.findIndex((r) => r.permaslug === permaslug);
    if (idx !== -1) {
      let http = Object.assign([], resources.http, { [idx]: data });
      mutateAll({ http, db: resources.db }, shouldRevalidate);
      return;
    }

    throw new Error(
      `Could not find a resource in cache matching that permaslug`
    );
  };

  let resource: DbResource | HttpResource | undefined = resources.db.find(
    (r) => r.permaslug === permaslug
  );
  resource = resource ?? resources.http.find((r) => r.permaslug === permaslug);

  if (!resource) {
    throw new Error(`Could not find a resource for that permaslug`);
  }

  return { mutate, resource };
};

type Resources = {
  http: HttpResource[];
  db: DbResource[];
};

export let useResources = (): {
  resources: Resources;
  mutate: (
    resources?: Resources,
    shouldRevalidate?: boolean
  ) => Promise<ListOrgResources | undefined>;
} => {
  let { data, mutate: mutateSWR, error } = useSWR<ListOrgResources, FetchError>(
    "/api/resources"
  );

  throwIfForbiddenError(error);

  let mutate = (resources?: Resources, shouldRevalidate?: boolean) => {
    if (resources) {
      return mutateSWR(() => ({ resources, ok: true }), shouldRevalidate);
    } else {
      return mutateSWR();
    }
  };

  let {
    resources: { http, db },
  } = responses.listOrgResources.parse(data);

  return {
    resources: {
      http: parseTimestamps(http),
      db: parseTimestamps(db),
    },
    mutate,
  };
};

export let useEnvs = (): {
  envs: Env[];
  mutate: (
    envs?: Env[],
    shouldRevalidate?: boolean
  ) => Promise<ListOrgEnvs | undefined>;
} => {
  let { data, mutate: mutateSWR, error } = useSWR<ListOrgEnvs, FetchError>(
    "/api/envs"
  );

  throwIfForbiddenError(error);

  let mutate = (envs?: Env[], shouldRevalidate?: boolean) => {
    if (envs) {
      return mutateSWR({ envs, ok: true }, shouldRevalidate);
    } else {
      return mutateSWR();
    }
  };

  let { envs } = responses.listOrgEnvs.parse(data);

  return {
    envs: parseTimestamps(envs),
    mutate,
  };
};

export let useQuery = (queryPermaslug: string, resourcePermaslug: string) => {
  let { queries, mutate: mutateAll } = useQueries();

  let mutate = (data: any, shouldRevalidate?: boolean) => {
    if (!queries) {
      // Only will get here if mutate is called prematurely
      throw new Error(
        `Attempted to get resource but none loaded. This will happen if you attempt to mutate before data has been fully loaded.`
      );
    }

    let idx = queries.db.findIndex((q) =>
      matchesQuery(q, queryPermaslug, resourcePermaslug)
    );
    if (idx) {
      let db = Object.assign([], queries.db, { [idx]: data });
      mutateAll({ db, http: queries.http }, shouldRevalidate);
      return;
    }
    idx = queries.http.findIndex((q) =>
      matchesQuery(q, queryPermaslug, resourcePermaslug)
    );
    if (idx) {
      let http = Object.assign([], queries.http, { [idx]: data });
      mutateAll({ http, db: queries.db }, shouldRevalidate);
      return;
    }

    throw new Error(`Could not find a query in cache matching that permaslug`);
  };

  let query: DbQuery | HttpQuery | undefined = queries.db.find((q) =>
    matchesQuery(q, queryPermaslug, resourcePermaslug)
  );
  query =
    query ??
    queries.http.find((q) =>
      matchesQuery(q, queryPermaslug, resourcePermaslug)
    );

  if (!query) {
    throw new Error(`Could not find a query for that permaslug`);
  }

  return { mutate, query };
};

function matchesQuery(
  query: DbQuery | HttpQuery,
  queryPermaslug: string,
  resourcePermaslug: string
): boolean {
  return (
    query.permaslug === queryPermaslug &&
    query.resource.permaslug === resourcePermaslug
  );
}

type Queries = {
  http: HttpQuery[];
  db: DbQuery[];
};

export let useQueries = (): {
  queries: Queries;
  mutate: (
    queries?: Queries,
    shouldRevalidate?: boolean
  ) => Promise<ListOrgQueries | undefined>;
} => {
  let { data, mutate: mutateSWR, error } = useSWR<ListOrgQueries, FetchError>(
    "/api/queries"
  );

  throwIfForbiddenError(error);

  let mutate = (queries?: Queries, shouldRevalidate?: boolean) => {
    if (queries) {
      return mutateSWR({ queries, ok: true }, shouldRevalidate);
    } else {
      return mutateSWR();
    }
  };

  let {
    queries: { http, db },
  } = responses.listOrgQueries.parse(data);

  return {
    queries: {
      http: parseTimestamps(http),
      db: parseTimestamps(db),
    },
    mutate,
  };
};

export let useOrg = (config: ConfigInterface = {}) => {
  let { data, mutate: mutateSWR, error } = useSWR<GetUserOrg, FetchError>(
    "/api/orgs/mine",
    config
  );

  throwIfForbiddenError(error);

  let mutate = (data: Organization, shouldRevalidate?: boolean) => {
    mutateSWR({ ok: true, org: data }, shouldRevalidate);
  };
  if (!data) {
    return { mutate };
  }

  let { org } = responses.getUserOrg.parse(data);
  return { org, mutate };
};

export let useUser = (config: ConfigInterface = {}) => {
  let { data, mutate: mutateSWR, error } = useSWR<GetUser, FetchError>(
    "/api/users/me",
    config
  );

  throwIfForbiddenError(error);

  let mutate = (user: User, shouldRevalidate?: boolean) => {
    mutateSWR({ ok: true, user }, shouldRevalidate);
  };

  if (!data) {
    return { mutate };
  }

  let { user } = responses.getUser.parse(data);

  return { user, mutate };
};

const parseTimestamps = (q: any) => {
  return ["insertedAt", "updatedAt"].reduce((memo, key) => {
    let val = memo[key];
    if (val) {
      return {
        ...q,
        [key]: moment(val),
      };
    }
    return q;
  }, q);
};

/**
 * For the 403 error to reach the error boundary we have to throw it here.
 */
function throwIfForbiddenError(
  error?: Error | NotAuthorizedError | ForbiddenError
) {
  if (error instanceof ForbiddenError) {
    throw error;
  }
}

import React, { useState } from "react";
import { Button } from "antd";
import { WhiteBox } from "../components/MainLayout";
import BarebonesLayout from "../components/BarebonesLayout";
import { registerUser } from "../api";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FormItem, Input } from "../components/Form";
import { normalizeErrorsForForm } from "../util/form";
import logo from "../images/decode-logo.svg";

type Inputs = {
  users: [
    {
      email: string;
      password: string;
    }
  ];
};

const defaultValues: Inputs = {
  users: [
    {
      email: "",
      password: "",
    },
  ],
};

export default function Signup() {
  const { handleSubmit, control, errors, setError } = useForm<Inputs>({
    defaultValues,
  });

  let [working, setWorking] = useState(false);
  let history = useHistory();

  let submitUser = async (fields: any) => {
    setWorking(true);
    let response = await registerUser({ user: fields.users[0] });

    if (response.isOk()) {
      history.push("/queries");
    } else {
      let normalizedErrors = normalizeErrorsForForm(response.error);
      normalizedErrors.forEach(({ name, errors }) => {
        setError(name, {
          message: errors.join(", "),
        });
      });

      setWorking(false);
    }
  };

  return (
    <BarebonesLayout>
      <h1 className="header">
        <img src={logo} alt="Decode logo" />
        <p>Get started with a free Decode account. No credit card required.</p>
      </h1>
      <WhiteBox>
        <div className="container">
          <div className="left">
            <p>
              <b>With Decode you can build:</b>
            </p>
            <ul>
              <li>Customer support tools</li>
              <li>Data entry tools</li>
              <li>Admin panels</li>
              <li>Metrics dashboards</li>
              <li>Tagging tools</li>
              <li>That Thing Sales Needs</li>
              <li>...and a whole lot more</li>
            </ul>
          </div>
          <div className="form-container">
            <form onSubmit={handleSubmit(submitUser)} autoComplete="off">
              <FormItem required label="Email" errors={errors}>
                <Input
                  control={control}
                  rules={{
                    required: "Email is required",
                    pattern: {
                      value: /^\S+@\S+\.\S+$/i,
                      message: "Please enter a valid email address",
                    },
                  }}
                  placeholder="paul@arakkis.com"
                  name="users[0].email"
                />
              </FormItem>

              <FormItem required label="Password" errors={errors}>
                <Input
                  control={control}
                  rules={{
                    required: "Password is required",
                    minLength: {
                      value: 8,
                      message: "Password has to be at least 8 characters long",
                    },
                  }}
                  placeholder="secret-spice-batteries"
                  name="users[0].password"
                  type="password"
                />
              </FormItem>

              <Button
                style={{ width: "100%" }}
                loading={working}
                type="primary"
                htmlType="submit"
              >
                Create free account
              </Button>
            </form>
          </div>
        </div>
      </WhiteBox>
      <style jsx>{`
        .container {
          display: grid;
          grid-template-columns: 1fr 1fr;
          padding: 15px;
        }
        .left ul {
          list-style: none;
        }
        .left li::before {
          content: "✓ ";
        }
        .form-container {
          padding: 0px 25px;
        }
        .header {
          display: grid;
          justify-items: center;
          grid-row-gap: 15px;
        }
        .header img {
          width: 90px;
        }
        .header p {
          max-width: 400px;
          text-align: center;
        }
      `}</style>
    </BarebonesLayout>
  );
}

import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import useSWR from "swr";
import { GetUser, responses } from "../api/types";
import { FetchError } from "../api";

export default function useAnalyticsTracking() {
  let location = useLocation();

  let { data } = useSWR<GetUser, FetchError>("/api/users/me", {
    suspense: false,
  });

  let parsed = data && responses.getUser.parse(data);
  let user = parsed && parsed.user;

  useEffect(() => {
    if (user) {
      window?.analytics?.identify(user.id, {
        org_id: user.orgId,
        email: user.email,
        createdAt: user.insertedAt,
      });
      window?.analytics?.group(user.orgId);
    }
  }, [user && user.id]);

  useEffect(() => {
    window?.analytics?.page();
  }, [location.pathname]);
}

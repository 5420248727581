import React from "react";
import { WhiteBox } from "../components/MainLayout";
import BarebonesLayout from "../components/BarebonesLayout";

export default function ForbiddenPage() {
  return (
    <BarebonesLayout>
      <div className="header">
        <h1>Forbidden</h1>
      </div>
      <WhiteBox>
        You are not allowed to access this page.
      </WhiteBox>
      <style jsx>{`
        .header {
          display: grid;
          grid-template-columns: 1fr auto;
          margin-bottom: 10px;
        }
        .message {
          font-family: monospace;
        }
      `}</style>
    </BarebonesLayout>
  );
}

import React, { useContext, useState } from "react";
import css from "styled-jsx/css";
import { Table, message, Tooltip, Popconfirm } from "antd";
import { ResourceKind, IResourceKind } from "../api/types";
import {
  DatabaseOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

interface Props {
  onSelect(res: { id: string; permaslug: string }): void;
  onDelete?(id: string, kind: IResourceKind): Promise<void>;
  resources: Resource[];
}

interface Resource {
  id: string;
  permaslug: string;
  name: string;
  kind: IResourceKind;
}

export default function ResourceList({ onSelect, resources, onDelete }: Props) {
  let [resourcesDeleting, setResourcesDeleting] = useState<string[]>([]);

  let handleDelete = async (id: string, kind: IResourceKind) => {
    if (!onDelete) return;

    setResourcesDeleting((resources) => resources.concat(id));
    await onDelete(id, kind);
    setResourcesDeleting((resources) => resources.filter((i) => i !== id));
  };

  return (
    <>
      <Table
        rowKey={"id"}
        columns={[
          {
            title: "Name",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "Kind",
            dataIndex: "kind",
            key: "kind",
            render(k: IResourceKind) {
              if (k === ResourceKind.Values.db) {
                return (
                  <div>
                    <DatabaseOutlined /> Database
                  </div>
                );
              } else {
                return (
                  <div>
                    <CloudUploadOutlined /> API
                  </div>
                );
              }
            },
          },
          {
            title: "",
            render(r: Resource) {
              if (!onDelete) {
                return null;
              }
              if (resourcesDeleting.includes(r.id)) {
                return (
                  <Tooltip title="Deleting">
                    <DeleteOutlined spin />
                  </Tooltip>
                );
              } else {
                return (
                  <Tooltip placement="left" title="Delete">
                    <Popconfirm
                      title={
                        <span>
                          Really delete? This resource and{" "}
                          <b>all its queries</b> will be deleted. You'll have to
                          call us to undo, and even then no guarantees 🤷‍♂️.
                        </span>
                      }
                      onConfirm={(e) => {
                        e?.stopPropagation();
                        handleDelete(r.id, r.kind);
                      }}
                      overlayStyle={{ maxWidth: "250px" }}
                    >
                      <span
                        onClick={(e) => e.stopPropagation()}
                        className="delete"
                      >
                        <DeleteOutlined />
                      </span>
                    </Popconfirm>
                  </Tooltip>
                );
              }
            },
          },
        ]}
        dataSource={resources}
        pagination={false}
        onRow={(r) => ({
          onClick: () => onSelect({ id: r.id, permaslug: r.permaslug }),
        })}
        rowClassName={rowClassName}
      />
      {styles}
    </>
  );
}

let { className: rowClassName, styles } = css.resolve`
  tr {
    cursor: pointer;
  }
`;

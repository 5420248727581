import React, { useState, useEffect } from "react";
import { Button, Divider, Input, Form } from "antd";
import { WhiteBox } from "../components/MainLayout";
import { GoogleOutlined, GithubOutlined } from "@ant-design/icons";
import BarebonesLayout from "../components/BarebonesLayout";
import {
  getLoginRedirectUrl,
  AuthProvider,
  checkIsLoggedIn,
  loginWithPassword,
  getLoginRedirectUrlOrgOauth,
} from "../api";
import { useQueryParams } from "../util/util";

export default function LoginInit() {
  let [providerInitiated, setProviderInitiated] = useState<
    AuthProvider | undefined
  >();
  // let [authUrl, setAuthUrl] = useState<undefined | string>();
  let [alreadyLoggedIn, setAlreadyLoggedIn] = useState(false);
  let { redirect_url: redirectUrl, org } = useQueryParams();
  let [error, setError] = useState<string | null>(null);

  useEffect(() => {
    let doEffect = async () => {
      let isLoggedIn = await checkIsLoggedIn();
      if (isLoggedIn) {
        setAlreadyLoggedIn(true);
      }
    };
    doEffect();
  }, []);

  useEffect(() => {
    let doEffect = async () => {
      if (org) {
        let url = await getLoginRedirectUrlOrgOauth(
          org as string,
          redirectUrl as string | undefined
        );
        window.location.href = url as string;
      }
    };
    doEffect();
  }, [org, redirectUrl]);

  useEffect(() => {
    if (alreadyLoggedIn) {
      if (redirectUrl) {
        if (typeof redirectUrl !== "string") {
          throw new Error(`Invalid redirect_url provided`);
        }
        window.location.href = redirectUrl;
      } else {
        window.location.href = "/queries";
      }
    }
  }, [alreadyLoggedIn, redirectUrl]);

  useEffect(() => {
    if (providerInitiated && providerInitiated !== AuthProvider.identity) {
      let url = getLoginRedirectUrl(
        providerInitiated,
        redirectUrl as string | undefined
      );
      window.location.href = url;
      // .then((url) => setRedirectUrl(url))
      // .catch((e) => {
      //   console.error(e);
      //   message.error(
      //     "Something went wrong :( Refresh the page and try one more time"
      //   );
      //   setProviderInitiated(undefined);
      // });
    }
  }, [providerInitiated, redirectUrl]);

  // if (authUrl) {
  //   window.location.href = authUrl;
  // }

  let handlersFor = (provider: AuthProvider) => ({
    loading: providerInitiated === provider,
    onClick: () => setProviderInitiated(provider),
  });

  let iconFor = (provider: AuthProvider) => {
    if (providerInitiated === provider) return null;

    switch (provider) {
      case AuthProvider.google: {
        return <GoogleOutlined />;
      }
      case AuthProvider.github: {
        return <GithubOutlined />;
      }
    }
  };

  let handleFinish = async ({ email, password }: { [k: string]: string }) => {
    setProviderInitiated(AuthProvider.identity);
    let err, url;
    [err, url] = await loginWithPassword(
      email,
      password,
      redirectUrl as string | undefined
    );
    setProviderInitiated(undefined);
    if (err) {
      setError(err);
      return;
    }
    window.location.href = url as string;
  };

  return (
    <BarebonesLayout>
      <h1 className="header">Login</h1>
      <WhiteBox>
        <div className="action-container">
          <Form onFinish={handleFinish}>
            {error && (
              <p style={{ color: "#f5222d" }}>
                Invalid email or password provided.
              </p>
            )}
            <Form.Item name="email" required>
              <Input placeholder="paul@arakkis.com"></Input>
            </Form.Item>
            <Form.Item name="password" required>
              <Input
                type="password"
                placeholder="secret-spice-batteries"
              ></Input>
            </Form.Item>
            <Button
              style={{ width: "100%" }}
              loading={providerInitiated === "identity"}
              type="primary"
              htmlType="submit"
            >
              Log in
            </Button>
          </Form>
          <Divider>or</Divider>
          <Button
            {...handlersFor(AuthProvider.google)}
            type="primary"
            size="large"
            style={{ backgroundColor: "#4285F4" }}
          >
            {iconFor(AuthProvider.google)}
            Login with Google
          </Button>
          <Button
            {...handlersFor(AuthProvider.github)}
            type="primary"
            size="large"
            style={{ backgroundColor: "#24292E" }}
          >
            {iconFor(AuthProvider.github)}
            Login with Github
          </Button>
        </div>
      </WhiteBox>
      <style jsx>{`
        .action-container {
          text-align: center;
          padding: 50px 0px;
          display: grid;
          grid-row-gap: 10px;
          max-width: 350px;
          margin: 0px auto;
        }
      `}</style>
    </BarebonesLayout>
  );
}

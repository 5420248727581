import TopNav from "./TopNav";
import { Layout as AntLayout } from "antd";
import React, { useState } from "react";
import { ArrowLeftOutlined, DoubleRightOutlined } from "@ant-design/icons";
import SideNav, { UnhideSidebarButton } from "./SideNav";

let { Content } = AntLayout;

interface Props {
  navHeader?: NavHeaderProps;
}

const MainLayout: React.FC<Props> = (props) => {
  let [sidebarHidden, setSidebarHidden] = useState(false);

  return (
    <AntLayout>
      <TopNav />
      <AntLayout className="main-container">
        {sidebarHidden ? (
          <UnhideSidebarButton onClick={() => setSidebarHidden(false)} />
        ) : (
          <SideNav onHide={() => setSidebarHidden(true)} />
        )}
        <Content>
          <div className="content-container">
            <div className="centered-column">
              {props.navHeader && <NavHeader {...props.navHeader} />}
              {props.children}
            </div>
          </div>
        </Content>
      </AntLayout>
      <style jsx>{`
        .content-container {
          display: grid;
          margin: 50px 10px 30px 10px;
          justify-content: center;
          grid-template-columns: minmax(300px, 600px);
        }

        .centered-column {
          width: 600px;
        }

        :global(.main-container) {
          min-height: calc(100vh - 40px);
        }
      `}</style>
    </AntLayout>
  );
};

export const WhiteBox: React.FC = (props) => {
  return (
    <div className="white-box">
      {props.children}
      <style jsx>{`
        .white-box {
          background-color: #fff;
          box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
          border-radius: 2px;
          padding: 15px;
          display: grid;
        }
      `}</style>
    </div>
  );
};

interface NavHeaderProps {
  title: string;
  onBack: () => void;
}

let NavHeader = ({ title, onBack }: NavHeaderProps) => (
  <div className="nav-header">
    <ArrowLeftOutlined onClick={onBack} />
    <span onClick={onBack}>{title}</span>
    <style jsx>{`
      .nav-header {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-column-gap: 10px;
        align-items: center;
        margin-bottom: 15px;
      }
      .nav-header .icon {
        cursor: pointer;
      }
      .nav-header span {
        cursor: pointer;
        width: fit-content;
      }

      span {
        color: rgba(0, 0, 0, 0.45);
      }
    `}</style>
  </div>
);

export default MainLayout;

import React from "react";
import { Select } from "antd";

import { Collapse } from "antd";
import { DbQuery as IDbQuery } from "../api/types";
import CopyIcon from "./CopyIcon";
import QueryValue from "./QueryValue";

interface Props {
  query: IDbQuery;
}

export default function DbQuery({ query }: Props) {
  let { slug, statement } = query;

  let urlPrefix = `https://api.usedecode.com/e/`;
  let url = [urlPrefix, slug].join("");

  return (
    <div className="db-query">
      <h3 className="field-label">Endpoint</h3>
      <div className="endpoint-slug">
        {url}
        <CopyIcon content={url} />
      </div>
      <h3 className="field-label">Statement</h3>
      <div className="field">
        <QueryValue value={statement} />
      </div>
      {/* <h3 className="field-label">Roles</h3>
      <div className="field">
        {roles.length > 0
          ? roles.map((role) => <span className="role">{role}</span>)
          : "None specified. Anyone can access."}
      </div> */}
      <style jsx>{`
        .edit-db-query {
          display: grid;
        }
        .endpoint-slug {
          margin-bottom: 15px;
          max-width: 450px;
        }
        .field-label {
          font-weight: 600;
        }
        .role {
          font-family: monospace;
          background-color: #d9d9d9;
          padding: 5px;
          border-radius: 2px;
        }
      `}</style>
    </div>
  );
}

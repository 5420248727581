import React from "react";
import {
  ResourceKind,
  IResourceKind,
  HttpResourceModifiableParams,
} from "../api/types";

import MainLayout, { WhiteBox } from "../components/MainLayout";
import EditDbResource from "../components/EditDbResource";
import EditHttpResource from "../components/EditHttpResource";
import { useHistory, useParams } from "react-router-dom";

import * as api from "../api";
import { useResources } from "../api/hooks";
import { message } from "antd";

export default function AddResource() {
  let { kind } = useParams<{ kind: IResourceKind }>();
  let { mutate } = useResources();
  let history = useHistory();

  let handleHttpSave = async (params: HttpResourceModifiableParams) => {
    await api.createHttpResource(params);
    mutate();
    history.push("/resources");
    message.success("Created");
  };

  let isDb = kind === ResourceKind.Values.db;

  return (
    <MainLayout
      navHeader={{
        onBack: () => history.push("/resources"),
        title: "Back to all resources",
      }}
    >
      <div className="header">
        <h1>{`Add new ${isDb ? "database" : "HTTP"} resource`}</h1>
      </div>
      <WhiteBox>
        {isDb ? (
          <EditDbResource mutate={mutate} />
        ) : (
          <EditHttpResource
            onSubmit={handleHttpSave}
            onCancel={() => history.push("/queries")}
          />
        )}
      </WhiteBox>
      <style jsx>{`
        .header {
          display: grid;
          grid-template-columns: 1fr auto;
          margin-bottom: 10px;
        }
        .header h1 {
          grid-column: 1 / span 2;
        }
      `}</style>
    </MainLayout>
  );
}

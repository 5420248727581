import React from "react";
import { Tooltip } from "antd";

const CalloutBubble: React.FC<{ active?: boolean }> = ({
  active = true,
  children,
}) => {
  return (
    <>
      <Tooltip
        visible={active}
        placement="left"
        overlayClassName={"CalloutBubble"}
        overlay=""
        transitionName=""
      >
        {children as any}
      </Tooltip>
      <style jsx global>{`
        .CalloutBubble {
          transform: translateX(-75%);
          animation: bob 1.5s ease-in-out 0s infinite alternate-reverse both;
        }

        @keyframes bob {
          from {
            transform: translateX(-75%);
          }

          to {
            transform: translateX(-100%);
          }
        }

        .CalloutBubble .ant-tooltip-inner {
          background-color: rgb(255, 133, 192, 0.8);
          border-radius: 15px;
        }

        .CalloutBubble .ant-tooltip-arrow {
          display: none;
        }
      `}</style>
    </>
  );
};

// This is a hack to allow CalloutBubble and Ant's Collapse to co-exist on same page
// Collapse performs a transition when toggled. We need to relocate the fixed position
// of Callout Bubble when its transition completes.
// Note I tried to just get rid of the transition but ran into trouble.
// const useRerenderWhenCollapseToggled = (active: boolean) => {
//   const forceUpdate = useForceUpdate();

//   useEffect(() => {
//     const getEl = () =>
//       document.getElementsByClassName("ant-collapse-header")[0];
//     const listener = () => forceUpdate();

//     const addListener = () => {
//       const el = getEl();
//       console.log("mutation observed");
//       if (el) {
//         console.log("adding listener");
//         el.addEventListener("transitionend", listener);
//       }
//     };
//     const observer = new MutationObserver(addListener);

//     if (active) {
//       console.log("doing observe");
//       addListener();
//       const parentDiv = document.getElementById("edit-query");
//       parentDiv && observer.observe(parentDiv, { childList: true });
//     }

//     return () => {
//       console.log("removing observations bro");
//       observer.disconnect();
//       const el = getEl();
//       el && el.removeEventListener("transitionend", listener);
//     };
//   }, [active]);
// };

// const useForceUpdate = () => {
//   const [value, setValue] = useState(true);
//   return () => setValue(!value);
// };

export default CalloutBubble;

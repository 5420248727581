import React from "react";
import {
  Controlled as CodeMirror,
  IControlledCodeMirror,
} from "react-codemirror2";

interface Props extends IControlledCodeMirror {
  mode: "simple-decode-vars" | "";
}

export default function CodeMirrorInput(props: Props) {
  let { options, mode, ...rest } = props;
  let extraKeys = options?.extraKeys ?? {};
  if (typeof extraKeys === "string") {
    throw new Error(`Pass a KeyMap to CodeMirrorInput`);
  }
  return (
    <CodeMirror
      {...rest}
      options={{
        mode,
        theme: "decode",
        scrollbarStyle: "null",
        dragDrop: false,
        ...options,
        extraKeys: {
          Enter: () => {},
          Tab: false,
          "Shift-Tab": false,
          ...extraKeys,
        },
      }}
    />
  );
}

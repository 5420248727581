import React, { useState, ReactElement } from "react";
import css from "styled-jsx/css";
import { Tooltip } from "antd";

let { className, styles } = css.resolve`
  .LocalMessage {
    -webkit-animation: scale-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
      both;
    animation: scale-in-bottom 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }

  .LocalMessage .ant-tooltip-inner {
  }

  .LocalMessage .ant-tooltip-arrow {
    display: none;
  }

  @-webkit-keyframes scale-in-bottom {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
      -webkit-transform-origin: 50% 100%;
      transform-origin: 50% 100%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transform-origin: 50% 100%;
      transform-origin: 50% 100%;
      opacity: 1;
    }
  }
  @keyframes scale-in-bottom {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
      -webkit-transform-origin: 50% 100%;
      transform-origin: 50% 100%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transform-origin: 50% 100%;
      transform-origin: 50% 100%;
      opacity: 1;
    }
  }
`;

interface Props {
  disappearAfter: number;
  children: (showMessage: (msg: string) => void) => ReactElement;
}
const LocalMessage: React.FC<Props> = ({
  disappearAfter,
  children,
  ...rest
}) => {
  const [msg, setMsg] = useState<string | undefined>();
  const showMessage = (msg: string) => {
    setMsg(msg);
    setTimeout(() => setMsg(undefined), disappearAfter);
  };

  console.log("STYLES");
  console.log(styles);

  return (
    <>
      <Tooltip
        overlayClassName={className}
        transitionName=""
        placement="right"
        visible={msg ? true : !!msg}
        title={msg}
        {...rest}
      >
        {children && children(showMessage)}
      </Tooltip>
      {/* https://github.com/zeit/styled-jsx/issues/322 */}
      <style jsx>{`
        ${styles}
      `}</style>
    </>
  );
};

export default LocalMessage;

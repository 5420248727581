import React from "react";
import { Controller, UseFormMethods } from "react-hook-form";
import { Input as AntInput } from "antd";
import { InputProps as AntInputProps } from "antd/lib/input";

type Name = string;

// Wrapped Antd components for ready use with react-hook-form

export interface InputProps extends Omit<AntInputProps, "name"> {
  control: UseFormMethods["control"];
  name: Name;
  rules?: any;
}

export let Input = ({ control, name, rules, ...rest }: InputProps) => (
  <Controller
    control={control}
    name={name}
    defaultValue=""
    rules={rules}
    render={({ onChange, onBlur, value, name }) => (
      <AntInput
        {...rest}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        // workaround to avoid Chrome AutoFill
        autoComplete="new-password"
      />
    )}
  />
);

import React from "react";
import { Layout, Menu, Avatar, Dropdown } from "antd";

import logo from "../images/decode-full.svg";
import { useHistory, Link } from "react-router-dom";
import { useUser } from "../api/hooks";
import { UserOutlined } from "@ant-design/icons";

let { Header: AntdHeader } = Layout;

export default function TopNav() {
  let { user } = useUser({ suspense: false });
  let history = useHistory();

  let userInitials =
    user?.name &&
    user.name
      .split(" ")
      .map((i) => i[0])
      .slice(0, 2)
      .join("");

  let menu = (
    <Menu>
      <Menu.Item>
        <Link to="/logout">Logout</Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <AntdHeader className="header">
      <div onClick={() => history.push("/queries")} className="brand">
        <img alt="Decode" src={logo} height="30" />
      </div>
      <div className="links">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://docs.usedecode.com/start"
        >
          Docs
        </a>
      </div>
      {user && (
        <div className="avatar">
          <Dropdown overlay={menu}>
            {userInitials ? (
              <Avatar style={{ backgroundColor: "#7D8691" }}>
                {userInitials}
              </Avatar>
            ) : (
              <Avatar
                icon={<UserOutlined />}
                style={{ backgroundColor: "#7D8691" }}
              />
            )}
          </Dropdown>
        </div>
      )}

      <style jsx>{`
        :global(.header.ant-layout-header) {
          background-color: #2e4052;
          color: #e3d8f1;
          height: 40px;
          line-height: 40px;
          display: grid;
          grid-template-columns: auto 1fr auto auto;
        }

        :global(.header-menu.ant-menu.ant-menu-dark) {
          background-color: #2e4052;
          margin-left: 50px;
        }

        .brand {
          align-items: center;
          margin: 0px;
          height: 30px;
          cursor: pointer;
        }

        .links {
          grid-column: 3;
          margin-right: 20px;
        }

        .links a {
          color: #fff;
        }

        .avatar {
          grid-column: 4;
          cursor: pointer;
        }
      `}</style>
    </AntdHeader>
  );
}
/* <Menu
        mode="horizontal"
        theme="dark"
        defaultSelectedKeys={[activeKey ?? ""]}
        className="header-menu"
      >
        <Menu.Item key="queries" onClick={() => history.push("/queries")}>
          <CodeOutlined />
          Queries
        </Menu.Item>
        <Menu.Item key="resources" onClick={() => history.push("/resources")}>
          <ApiOutlined /> Resources
        </Menu.Item>
      </Menu> */

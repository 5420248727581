import React from "react";
import { Layout as AntLayout } from "antd";
import TopNav from "./TopNav";

const { Content } = AntLayout;

const BarebonesLayout: React.FC = (props) => {
  return (
    <AntLayout>
      <TopNav />
      <AntLayout className="main-container">
        <Content>
          <div className="content-container">
            <div className="centered-column">{props.children}</div>
          </div>
        </Content>
      </AntLayout>
      <style jsx>{`
        .content-container {
          display: grid;
          margin: 50px 10px 30px 10px;
          justify-content: center;
          grid-template-columns: minmax(300px, 600px);
        }

        .centered-column {
          width: 600px;
        }

        :global(.main-container) {
          min-height: calc(100vh - 40px);
        }
      `}</style>
    </AntLayout>
  );
};

export default BarebonesLayout;

import React, { useState, useEffect } from "react";
import { Select, Input, Radio, Button, Form } from "antd";
import {
  HttpResource,
  SecuritySchemeKind,
  ApiKeyLocation,
  HttpResourceModifiableParams,
} from "../api/types";
import SecretInput from "./SecretInput";
import { useEnvs } from "../api/hooks";

let { Option } = Select;

interface Props {
  resource?: HttpResource;
  onCancel(): void;
  onSubmit(p: Partial<HttpResourceModifiableParams>, id?: string): void;
}

interface State {
  name: string;
  envId?: string;
  definition: {
    url: string;
    securityScheme: {
      apiKey: {
        name: string;
        secret: string;
        location: ApiKeyLocation;
      };
      bearer: {
        secret: string;
      };
      basic: {
        user: string;
        secret: string;
      };
    };
  };
}

let initialSecurity = {
  apiKey: {
    name: "",
    secret: "",
    location: "query" as "query",
  },
  bearer: {
    secret: "",
  },
  basic: {
    user: "",
    secret: "",
  },
};

let getInitialSecuritySchemeKind = (
  resource?: HttpResource
): SecuritySchemeKind => {
  if (resource) {
    let security = resource.definition.securityScheme;
    return security.scheme;
  } else {
    return "apiKey";
  }
};

let getInitialSecurityScheme = (resource?: HttpResource) => {
  if (resource) {
    let security = resource.definition.securityScheme;
    let { type: _type, scheme, ...restScheme } = security;
    return { ...initialSecurity, [scheme]: restScheme };
  }
  return {
    ...initialSecurity,
  };
};

export default function EditHttpResource({
  resource,
  onSubmit,
  onCancel,
}: Props) {
  let definition = resource?.definition;
  let { envs } = useEnvs();
  let [form] = Form.useForm();

  let [scheme, setScheme] = useState<SecuritySchemeKind>(
    getInitialSecuritySchemeKind(resource)
  );
  let initialValues: State = {
    name: resource?.name || "",
    definition: {
      url: definition?.url || "",
      securityScheme: {
        ...getInitialSecurityScheme(resource),
      },
    },
    envId: resource?.envId,
  };

  // Set default value for environment select
  useEffect(() => {
    if (!form.isFieldTouched("envId")) {
      form.setFields([{ name: "envId", value: resource?.envId }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [envs]);

  let handleFinish = (values: { [k: string]: any }) => {
    let schemes = values.definition.securityScheme;
    let securityScheme = {
      ...schemes[scheme],
      type: "http" as "http",
      scheme: scheme as any,
    };

    onSubmit(
      {
        name: values.name,
        definition: {
          url: values.definition.url,
          securityScheme,
        },
        envId: values.envId ? values.envId : resource?.envId,
      },
      resource?.id
    );
  };

  return (
    <div className="container">
      <Form form={form} initialValues={initialValues} onFinish={handleFinish}>
        <div className="label">Name</div>
        <Form.Item name="name" rules={[{ required: true }]}>
          <Input placeholder="e.g. Admin API - Production" />
        </Form.Item>

        <div className="label">URL</div>
        <Form.Item name={["definition", "url"]} rules={[{ required: true }]}>
          <Input placeholder="e.g. https://api.acme.org" />
        </Form.Item>

        {envs?.length > 1 && (
          <>
            <div className="label">Environment</div>
            <Form.Item name="envId">
              <Select>
                {envs.map(({ id, name }) => {
                  return <Option value={id}>{name}</Option>;
                })}
              </Select>
            </Form.Item>
          </>
        )}

        <AuthForm2 scheme={scheme} onSchemeChange={setScheme} />
        <div className="action-buttons">
          <Button onClick={onCancel}>Cancel</Button>
          <Button htmlType="submit" type="primary">
            Save
          </Button>
        </div>
      </Form>
      <style jsx>{`
        .container :global(form) {
          display: grid;
          grid-template-columns: 150px 1fr;
          grid-row-gap: 15px;
        }

        .label {
          font-weight: 600;
          font-size: 1.1em;
        }
        .action-buttons {
          width: 100%;
          grid-column: 2;
          display: grid;
          grid-template-columns: min-content min-content;
          justify-content: right;
          grid-column-gap: 15px;
          margin-top: 25px;
        }
      `}</style>
    </div>
  );
}

let AuthForm2 = ({
  scheme,
  onSchemeChange,
}: {
  scheme: SecuritySchemeKind;
  onSchemeChange(s: SecuritySchemeKind): void;
}) => {
  let apiKeyActive = scheme === "apiKey";
  let bearerActive = scheme === "bearer";
  let basicActive = scheme === "basic";
  let noneActive = scheme === "none";

  let apiKeyDivStyle = apiKeyActive
    ? { gridColumn: 2 }
    : { display: "none" as "none" };
  let bearerDivStyle = bearerActive
    ? { gridColumn: 2 }
    : { display: "none" as "none" };
  let basicDivStyle = basicActive
    ? { gridColumn: 2 }
    : { display: "none" as "none" };

  return (
    <>
      <div className="label">Authentication</div>

      <Radio.Group
        value={scheme}
        onChange={({ target: { value } }) => {
          onSchemeChange(value);
        }}
        style={{ display: "grid", gridRowGap: "12px" }}
      >
        <div className="radio-field">
          <Radio
            value={SecuritySchemeKind.Values.apiKey}
            style={{ gridColumn: "1 / span 2" }}
          >
            API key in a query param, cookie, or header
          </Radio>
          <div style={apiKeyDivStyle}>
            <div style={{ display: "grid", marginTop: "10px" }}>
              <div className="field-label">Where should it go?</div>
              <Form.Item
                name={["definition", "securityScheme", "apiKey", "location"]}
                rules={[
                  { required: apiKeyActive },
                  { enum: ["query", "header", "cookie"] },
                ]}
              >
                <Select>
                  <Option value="query">Query</Option>
                  <Option value="header">Header</Option>
                  <Option value="cookie">Cookie</Option>
                </Select>
              </Form.Item>
              <div className="field-label">Parameter name</div>
              <Form.Item
                name={["definition", "securityScheme", "apiKey", "name"]}
                rules={[{ required: apiKeyActive }]}
              >
                <Input placeholder="e.g. my_api_key" />
              </Form.Item>
              <div className="field-label">Key</div>
              <Form.Item
                name={["definition", "securityScheme", "apiKey", "secret"]}
                help="Same API key will be used for all users."
                rules={[{ required: apiKeyActive }]}
              >
                <SecretInput placeholder="shhhh" />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="radio-field">
          <Radio
            value={SecuritySchemeKind.Values.bearer}
            style={{ gridColumn: "1 / span 2" }}
          >
            Bearer token
          </Radio>
          <div style={bearerDivStyle}>
            <p className="secondary">
              Key sent along in "Authorization" header as "Bearer {`{token}`}".
            </p>

            <div style={{ display: "grid", marginTop: "10px" }}>
              <div className="field-label">Token</div>
              <Form.Item
                name={["definition", "securityScheme", "bearer", "secret"]}
                rules={[{ required: bearerActive }]}
                help="Same token will be used for all users."
              >
                <SecretInput />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="radio-field">
          <Radio
            value={SecuritySchemeKind.Values.basic}
            style={{ gridColumn: "1 / span 2" }}
          >
            HTTP Basic
          </Radio>
          <div style={basicDivStyle}>
            <div style={{ display: "grid", marginTop: "10px" }}>
              <div className="field-label">User</div>
              <Form.Item
                name={["definition", "securityScheme", "basic", "user"]}
                rules={[{ required: basicActive }]}
              >
                <Input />
              </Form.Item>
              <div className="field-label">Password</div>
              <Form.Item
                name={["definition", "securityScheme", "basic", "secret"]}
                rules={[{ required: basicActive }]}
                help="Same credentials will be used for all users."
              >
                <SecretInput />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="radio-field">
          <Radio value={"none"} style={{ gridColumn: "1 / span 2" }}>
            None
            {noneActive && (
              <p className="secondary">This resource is open to the world.</p>
            )}
          </Radio>
        </div>
      </Radio.Group>
      <style jsx>{`
        .label {
          font-weight: 600;
          font-size: 1.1em;
        }

        .field-label {
          font-weight: 600;
        }

        .auth-field {
          display: grid;
          grid-row-gap: 7px;
        }

        .radio-field {
          display: grid;
          grid-template-columns: 24px 1fr;
        }

        p.secondary {
          color: #8c8c8c;
        }
      `}</style>
    </>
  );
};
